import AuthService from "../services/auth";
import store from "./store";
import A from "./actionTypes";

let tokenUpdateTimer;

const renewTokenIn =
  // 1000 * 10; // testing token renewal process
  process.env.REACT_APP_TOKEN_RENEW || 1000 * 60 * 28; // ms. renew token in 28 minutes

const usageThreshold =
  // 1000 * 3; // testing token renewal process
  process.env.REACT_APP_TOKEN_RENEW_THRESHOLD || 1000 * 60 * 10; // renew only if used in the last 10 minutes

class Actions {
  //   static async onUpdateToken(tokenTimestamp) {
  static async onUpdateToken(renewTime = renewTokenIn) {
    console.debug("onUpdateToken: renewing in ", renewTime);
    this.clearTokenUpdateTimer();
    tokenUpdateTimer = setTimeout(async () => {
      const { lastUsed, user } = store.getState().auth;
      const now = Date.now();
      this.counter += 1;
      // const counter = this.counter;

      // if not logged in - do nothing
      // (this should not happen at all, actually)
      if (!user) return;

      // if has been used recently, renew the token
      console.debug("onUpdateToken lastUsed stamp:", lastUsed);
      console.debug("onUpdateToken now:", now);
      console.debug(
        "onUpdateToken time since last used timestamp:",
        now - lastUsed,
        "onUpdateToken treshold:",
        usageThreshold
      );

      if (now - lastUsed <= usageThreshold) {
        return await AuthService.renew();
      }
      // not has been used recently - log out
      await AuthService.logout();
    }, renewTime);
  }

  static checkIfTokenHasToBeUpdated() {
    const now = Date.now();

    // get timestamp from state
    const { tokenTimestamp } = store.getState().auth || { tokenTimestamp: now };

    // renew in time, that equals *default renew time* minus *the time already spent since tokenTimestamp*
    // this is needed for when a page is reloaded and renew token timer has to be set again.
    const tokenUpdatedTimespan = renewTokenIn - (now - tokenTimestamp);

    if (tokenUpdatedTimespan > 0) {
      // if some time has passed since last timestamp but renew lapse hasn't run out - run onUpdateToken with updated timespan
      return this.onUpdateToken(tokenUpdatedTimespan);
    }

    // renew time lapse has run out - attempt to renew the token (if it's valid, it'll be renewed, otherwise the user is logged out)
    AuthService.renew();
  }

  static clearTokenUpdateTimer() {
    clearTimeout(tokenUpdateTimer);
  }

  /**
   * Update UI usage timestamp
   */
  static updateUIUsageTimestamp = () => {
    console.debug("updating UI usage timestamp");
    store.dispatch({ type: A.SET_USAGE_TIMESTAMP });
  };
}

Actions.isFetchingToken = false;
Actions.counter = 0;

export default Actions;
