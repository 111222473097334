// import tinymce from '@bit/ses-education.ses-courses-shared-components.tinymce-editor/tiny';
import tinymce from "tinymce/tinymce";

const icons = [
  {
    text: "Eye",
    id: "eye",
    icon: "preview",
    image:
      "https://neulog.com/temp/sense/content/en/P02_Play_files/image030.png",
  },
  {
    text: "Turn On",
    id: "start",
    icon: "brightness",
    image:
      "https://neulog.com/temp/sense/content/en/P02_Play_files/image018.png",
  },
  {
    text: "ToDo",
    id: "post-it",
    icon: "brightness",
    image:
      "https://neulog.com/temp/sense/content/en/P02_Play_files/image027.png",
  },
  {
    text: "Put Down",
    id: "place",
    icon: "brightness",
    image:
      "https://neulog.com/temp/sense/content/en/P02_Play_files/image024.png",
  },
  { text: "Question", id: "question" },
  { text: "Plan", id: "plan", icon: "plan" },
  { text: "Save", id: "save", icon: "save" },
  { text: "Sensors", id: "sensors", icon: "sensors" },
  { text: "Load", id: "load", icon: "sensors" },
  { text: "Open", id: "open", icon: "open" },
];

// export const addIconsButtons = "all-icons-button"
export const addIconsButtons = icons.map((i) => `add-${i.id}-icon`).join(" ");
export const addIconsFormats = icons.reduce((res, i) => {
  return {
    ...res,
    [`${i.id}-icon`]: {
      selector: "p,div,ul,ol,h1,h2,h3,h4,h5,h6",
      classes: "icon " + i.id,
    },
    // "eye-icon": { selector: "p,div,ul,ol,h1,h2,h3,h4,h5,h6", classes: "with-icon eye"},
  };
}, {});

tinymce.PluginManager.add("add-icon", function (editor, url) {
  const createButton = (iconData) => {
    const { id, icon, image, text } = iconData;

    // Add the button
    editor.ui.registry.addButton(`add-${id}-icon`, {
      image,
      text,
      onAction: () => {
        const theFormat = `${id}-icon`;

        const formatter = editor.formatter;
        const alreadyApplied = formatter.matchAll(
          icons.map((i) => `${i.id}-icon`)
        );

        // remove all already applied formats
        if (alreadyApplied.length > 0) {
          alreadyApplied.forEach((f) => formatter.remove(f));
        }

        // if the current format was not previously applied, apply it.
        if (!alreadyApplied.includes(theFormat)) formatter.apply(theFormat);
      },
    });
  };

  // add all buttons
  icons.forEach((i) => {
    createButton(i);
  });

  // editor.ui.registry.addGroupToolbarButton("all-icons-button", {
  //   text: "Icons",
  //   items: icons.map((i) => `add-${i.id}-icon`).join(" "),
  //   tooltip: "All icon buttons",
  // });

  // // Adds a menu item, which can then be included in any menu via the menu/menubar configuration
  // editor.ui.registry.addMenuItem('text-field', {
  //   text: 'Inline text field',
  //   onAction: function() {
  //   }
  // });

  return {
    getMetadata: function () {
      return {
        name: "Icons (Bluefox)",
      };
    },
  };
});
