import React from "react";
import "./style.scss";
import ProgramEditor from "../ProgramEditor";
import ProgramService from "../../services/programService";
import {
  ButtonGroup,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import TranslationService from "../../services/translationService";
import ErrorMessage from "../template/ErrorMessage";
import Spinner from "../template/Spinner";

class Program extends React.Component {
  state = {
    showDuplicateDialog: false,
    duplicateTargetLanguage: null,
    languages: null,
    containerWidth: 550,
  };

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const languages = await TranslationService.getLanguages();
    if (!languages) {
      // console.debug("Languages not received. Result:")
      console.error(TranslationService.error);
    }

    this.setState({ languages });
  }

  duplicate = async (program_id, lang, title, html) => {
    console.debug("duplicating", program_id, lang, title, html);

    const duplicated = await ProgramService.updateTitleAndText(
      program_id,
      lang,
      title,
      html
    );
    if (!duplicated) {
      alert(ProgramService.error);
      return false;
    }

    this.setState({ showDuplicateDialog: false });

    // update
    const { onUpdate } = this.props;
    if (onUpdate && typeof onUpdate === "function") onUpdate();
  };

  updateTitle = async (program_id, lang, title) => {
    const newTitle = prompt("Edit the program title", title);
    console.debug(newTitle);
    if (newTitle === null) {
      return false;
    }

    const updated = await ProgramService.updateTitle(
      program_id,
      lang,
      newTitle
    );
    if (!updated) {
      alert(ProgramService.error);
      return false;
    }

    // update
    const { onUpdate } = this.props;
    if (onUpdate && typeof onUpdate === "function") onUpdate();
  };

  onSave = async (html) => {
    const { id, program, lang = "en", onUpdate } = this.props;

    const updated = await ProgramService.updateTranslation(
      program.program_id,
      lang,
      html
    );
    if (!updated) {
      alert(ProgramService.error);
      return false;
    }

    // call onUpdate callback, if any
    if (onUpdate && typeof onUpdate === "function") onUpdate();

    return true;
  };

  render() {
    const { id, program, lang = "en" } = this.props;
    const {
      languages,
      showDuplicateDialog,
      duplicateTargetLanguage,
      containerWidth,
    } = this.state;

    if (languages === null) return <Spinner />;
    if (languages === false)
      return (
        <ErrorMessage
          text="Please reload the page"
          title="Data fetching error"
        />
      );

    const existingLanguages = Object.keys(program.html);
    const availableLanguages = languages.filter(
      (l) => !existingLanguages.includes(l.iso639_1)
    );
    console.debug(availableLanguages);

    const html = program.html[lang] || "";
    const title = program.title[lang] || "untitled";

    return (
      <>
        <div className="program-editor">
          <div className="buttons">
            <ButtonGroup className="language-selector">
              {existingLanguages.map((lng, ind) => {
                return (
                  <Button
                    key={`lang-${lng}`}
                    component={NavLink}
                    to={`/programs/${program.program_type}/${program.slug}${
                      lng === "en" ? "" : `/${lng}`
                    }`}
                    exact={true}
                  >
                    {lng}
                  </Button>
                );
              })}
              {availableLanguages.length > 0 && (
                <Button
                  onClick={() => this.setState({ showDuplicateDialog: true })}
                >
                  Copy to new language
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  this.updateTitle(program.program_id, lang, title)
                }
              >
                Edit title
              </Button>
            </ButtonGroup>
          </div>
          <ProgramEditor
            {...{
              html,
              title,
              slug: program.slug,
              program_id: program.program_id,
              program_type: program.program_type,
              category_slug: program.category_slug,
              containerWidth,
              lang,
            }}
            onSave={this.onSave}
          />
        </div>

        <Dialog
          open={showDuplicateDialog}
          onClose={() => this.setState({ showDuplicateDialog: false })}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Select language to copy the content to</DialogTitle>
          <DialogContent>
            <Select
              fullWidth
              value={duplicateTargetLanguage}
              name="duplicateTargetLanguage"
              onChange={({ target }) => {
                this.setState({ [target.name]: target.value });
              }}
            >
              {availableLanguages.map((l) => (
                <MenuItem value={l.iso639_1}>
                  {l.local_name} ({l.english_name})
                </MenuItem>
              ))}
            </Select>
            <div className="buttons">
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  this.duplicate(
                    program.program_id,
                    duplicateTargetLanguage,
                    title,
                    html
                  )
                }
                disabled={!duplicateTargetLanguage}
              >
                Copy
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.setState({ showDuplicateDialog: false })}
              >
                Cancel
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default Program;
