import React from "react";
import { Button, List, ListItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import AdminTable from "../template/admin-table";
import BaseProgramService from "../../services/base-program-service";
import TranslationService from "../../services/translationService";

class TranslationsSection extends React.Component {
  state = {
    programs: null,
    error: null,
  };
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.section !== this.props.section) {
      this.fetchData();
    }
  }

  async fetchData() {
    const { section } = this.props;
    // get programs from server
    const programs = await TranslationService.getPrograms();

    if (!programs) {
      return this.setState({ programs: null, error: TranslationService.error });
    }

    console.debug("section", section, "programs", programs);
    //  store programs in state
    this.setState({
      programs: Array.isArray(programs)
        ? // filter programs by section id
          programs.filter(
            (p) => p.base_program_section_id === section.section_id
          )
        : [],
    });
  }

  render() {
    const { section, language_id } = this.props;
    if (!section) return "";

    const { programs } = this.state;

    const rows = programs
      ? programs.map((p) => ({ ...p, id: p.base_program_id }))
      : [];
    const buttons = [];

    const columns = [
      {
        field: "id",
        hide: true,
      },
      {
        field: "base_program_title",
        headerName: "Program title",
        width: 400,
        renderCell: (params) => {
          return (
            <Link
              to={`/translations/${language_id}/${section?.section_id}/${params.row.base_program_id}`}
            >
              {params.value}
            </Link>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: "3",
        renderCell: (params) => {
          return (
            <div className="actions">
              {/* <Button
                variant="contained"
                size="small"
                // color="primary"
                onClick={() => this.setState({ editItem: params.row })}
              >
                Rename
              </Button> */}
            </div>
          );
        },
      },
    ];

    return (
      <div className="section">
        <AdminTable autoHeight {...{ columns, rows, buttons }} />
      </div>
    );
  }
}

export default TranslationsSection;
