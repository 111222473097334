import baseClientService from "./baseClientService";

class BaseProgramService extends baseClientService {
  static async getAll() {
    try {
      return await this.GET("/admin/base-programs/all");
    } catch (e) {
      return this.Error(
        e.data || e?.response?.responseText || "Unknown server error"
      );
    }
  }

  static async getSectionPrograms(section_id) {
    const programs = await this.getAll();
    if (!programs) return false;

    return programs.filter((program) => program.section_id === section_id);
  }

  static async update(data) {
    try {
      return await this.POST("/admin/base-programs/update", data);
    } catch (e) {
      console.debug(e);
      return this.Error(
        e?.data || e?.response?.responseText || "Unknown server error"
      );
    }
  }

  /**
   * Adds content to base program
   * @param {*} base_program_id
   * @param {*} program_id
   * @returns
   */
  static async linkContent(base_program_id, program_id) {
    try {
      return await this.POST(
        `/admin/base-programs/link-content/${base_program_id}/${program_id}`
      );
    } catch (e) {
      console.debug(e);
      return this.Error(
        e?.data || e?.response?.responseText || "Unknown server error"
      );
    }
  }

  static async createContent(base_program_id, category_id) {
    try {
      return await this.POST(
        `/admin/base-programs/create-content/${base_program_id}/category/${category_id}`
      );
    } catch (e) {
      console.debug(e);
      return this.Error(
        e?.data || e?.response?.responseText || "Unknown server error"
      );
    }
  }

  /**
   * Remove content from base program
   */
  static async unlinkContent(content_id) {
    try {
      return await this.POST(
        `/admin/base-programs/unlink-content/${content_id}`
      );
    } catch (e) {
      console.debug(e);
      return this.Error(
        e?.data || e?.response?.responseText || "Unknown server error"
      );
    }
  }
}

baseClientService.extend(BaseProgramService);

export default BaseProgramService;
