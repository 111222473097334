import React from "react";

const { REACT_APP_DISPLAY_DOMAIN: ROOT } = process.env;
export default (props) => {
  const prepareHtml = (content) => {
    return `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <link rel="stylesheet" href="../../css/custom-content.css">
            <title>Test View</title>
        </head>
        <body>
            ${content}            
        </body>
        </html>
        `;
  };

  // const dochtml = prepareHtml(props.html);

  // console.debug("dochtml", dochtml)

  const source = `${ROOT}/items/${props.program_id}/${props.lang || "en"}.html`;
  return (
    // <iframe className="display-iframe" srcDoc={dochtml}></iframe>
    <iframe className="display-iframe" src={source}></iframe>
  );
};
