import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Button,
  Modal,
  InputLabel,
} from "@material-ui/core";
import React from "react";
import "./style.scss";

/**
 * Base program object editor.
 * @param {object} props
 */
class SectionProgramForm extends React.Component {
  state = {
    base_program_id: null,
    base_program_title: "",
    base_program_section_id: null,
  };

  componentDidMount() {
    const { item } = this.props;
    console.debug("SectionProgramForm item", item);
    // if (item) {
    this.setState(item, () => console.debug("SectionProgramForm", this.state));
    // }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.section !== this.props.section ||
      prevProps.item !== this.props.item
    ) {
      this.setState(this.props.item);
    }
  }

  onChange = (e) => {
    console.debug("onChange", e.target.value, e.target.name);
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onSave = async () => {
    const { onSave = console.debug, onClose } = this.props;
    if (await onSave(this.state)) {
      onClose();
    }
  };

  render() {
    const { open, onClose, sections } = this.props;

    console.debug("SectionProgramForm props", this.props);
    const { base_program_id, base_program_title, base_program_section_id } =
      this.state;
    return (
      <Modal {...{ open, onClose }} className="modal-form-wrapper">
        <div className="modal-form section-program-form">
          <TextField
            name={"base_program_title"}
            value={base_program_title}
            label="Title"
            onChange={this.onChange}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                this.onSave();
              }
            }}
          />

          {base_program_id && (
            <FormControl>
              <Select
                name="base_program_section_id"
                value={base_program_section_id}
                onChange={this.onChange}
              >
                {sections.map((section) => (
                  <MenuItem key={section.section_id} value={section.section_id}>
                    {section.section_title_translation_id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Button variant="contained" color="primary" onClick={this.onSave}>
            Save
          </Button>
        </div>
      </Modal>
    );
  }
}

export default SectionProgramForm;
