import { Button, TextField } from "@material-ui/core";
import React from "react";
import ModalWindow from "../template/modal-dialog";
import "./style.scss";

class PasswordChangeForm extends React.Component {
  state = {
    password1: "",
    password2: "",
    canSubmit: false,
    error: null,
  };

  componentDidUpdate(prevProps) {
    if (this.props.open === false && prevProps.open === true) {
      // clear password fields when closing
      this.setState({ password1: "", password2: "" });
    }
  }

  /**
   * Update field value
   * @param {*} ev
   */
  onValueChange = (ev) => {
    const { password1, password2 } = this.state;
    const { name, value } = ev.target;
    const otherValue = name === "password1" ? password2 : password1;

    // show error if both paswords are present but don't match
    const error =
      otherValue && value && otherValue !== value
        ? "Passwords do not match"
        : null;

    const canSubmit = otherValue && value && otherValue === value;

    this.setState({ [name]: value, error, canSubmit });
  };

  render() {
    const { onSubmit = console.debug, ...other } = this.props;
    const { password1, password2, canSubmit, error } = this.state;

    return (
      <ModalWindow header="Change Password" {...other} formClassName="shrink">
        <div className="change-password-form">
          <TextField
            name="password1"
            label="New Password"
            type="password"
            value={password1}
            onChange={this.onValueChange}
          />
          <TextField
            name="password2"
            label="Confirm New Password"
            type="password"
            value={password2}
            onChange={this.onValueChange}
          />
          <div className="error-message">{error}</div>
          <Button
            disabled={!canSubmit}
            variant="contained"
            color="primary"
            onClick={() => onSubmit(password1)}
          >
            Change Password
          </Button>
        </div>
      </ModalWindow>
    );
  }
}

export default PasswordChangeForm;
