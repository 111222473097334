import baseClientService from "./baseClientService";

class ContentService extends baseClientService {
  static async getContent(content_id) {
    try {
      const result = await this.GET(`/admin/content/${content_id}`);
      return result;
    } catch (e) {
      return this.Error("Error fetching content: ", e.message);
    }
  }
}

baseClientService.extend(ContentService);

export default ContentService;
