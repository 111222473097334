import { FormControl, FormLabel, TextField, debounce } from '@material-ui/core';
import React from 'react'
import uniqid from 'uniqid';


class SelectSearch extends React.Component{

    state = {
        // selected: null, // item object that is selected
        id: null,        
        searchValue: "",
        value: ""
    }

    componentDidMount(){
        const id = uniqid("select-search-");
        const {value, items, titleField = "title", valueField = "id"} = this.props;

        // find the value in list
        const item = value ? items.find(l => l[valueField] === value ) : undefined;
        
        // set its title to search value
        const searchValue = item ? item[titleField] || "" : "";


        this.setState({id, value, searchValue})
    }

    componentDidUpdate(prevProps){
        if( prevProps.value !== this.props.value ){

            const {value, items, titleField = "title", valueField = "id"} = this.props;

            // find the value in list
            const item = value ? items.find(l => l[valueField] === value ) : undefined;
            
            // set its title to search value
            const searchValue = item ? item[titleField] || "" : "";

            this.setState({searchValue, value});
        }
    }

    /**
     * Updates state based on search value.
     * If value found in title fields within items list, the props.onSelect callback is valled with the found item and the props.name value    
     * The call is debounced by props.debounceTime which defaults to 200ms
     * @param {Event} event oninput event
     */
    onChange =  ({target}) => {
        const {value} = target || {}        
        console.debug("changing to ", value);
        const {items = [], debounceTime = 200, onSelect = console.debug , name, titleField = "title" } = this.props;
        this.setState({ searchValue: value }, () => this.callCallback(items, titleField, value, onSelect, name, debounceTime)() );
    }   

    callCallback(items, titleField, value, onSelect, name, debounceTime) {
        return debounce(() => {
            const selected = items.find(i => i[titleField] && i[titleField] === value);            
            onSelect(selected, name)

        }, debounceTime);
    }

    render(){
        const {items = [], label, name, titleField = "title", valueField="id", formControlProps = {}} = this.props;
        const {id, searchValue} = this.state;
        // no id - wait for it to be set
        if( !id) return <>...</>
        return(
            <>                        
            <FormControl {...formControlProps}>
                { label && <FormLabel>{label}</FormLabel>}
                <TextField value={searchValue} inputProps={ {
                    // state the datalist as list for the input
                    list : `${id}-datalist`,
                    id: id,
                    name: name,
                    value: searchValue
                    }  }  
                    onInput={this.onChange}
                    />
                <datalist id={`${id}-datalist`}>
                    { items.map( (i,ind) =><option>{i[titleField] || ind}</option> )}                    
                </datalist>
            </FormControl>
            </>
        )
    }
}

export default SelectSearch