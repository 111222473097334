import { CircularProgress } from "@material-ui/core";
import { Home } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import BaseProgramService from "../../services/base-program-service";
import SectionService from "../../services/sectionService";
import AdminTable from "../template/admin-table";
import AutoBreadcrumbs from "../template/AutoBreadcrumbs";
import ErrorMessage from "../template/ErrorMessage";
import Section from "./section";
import SectionProgram from "./section-program";
import SectionProgramCategory from "./section-program-category";
import "./style.scss";

const columns = [
  {
    field: "id",
    headerName: "#",
    hidden: true,
  },
  //   {
  //     field: "section_id",
  //     headerName: "Id",
  //   },
  {
    field: "section_title_translation_id",
    headerName: "Title",
    flex: "1",
    align: "left",
    renderCell: (params) => {
      return (
        <Link to={`/sections/${params.row.section_id}`}>{params.value}</Link>
      );
    },
  },
];

class Sections extends React.Component {
  state = {
    sections: null,
    error: null,
    section: null,
    program: null,
    category: null,
  };

  async componentDidMount() {
    this.fetchData();
  }

  async componentDidUpdate(prevProps) {
    const { section_id, program_id } = this.props.match.params;

    if (prevProps.match.params.section_id !== section_id) {
      const { sections } = this.state;

      const section = this.selectSection(sections, section_id);
      this.setState({ section });
    }

    if (prevProps.match.params.program_id !== program_id) {
      const program = await this.selectBaseProgram(program_id);
      this.setState({ program });
    }
  }

  /**
   * Fetch sections data from server
   */
  async fetchData(forceReload = false) {
    let error = 0,
      section = null,
      program = null,
      category = null;

    const sections = await SectionService.getAll(forceReload);
    if (!sections) error = SectionService.error;

    const {
      section_id,
      program_id,
      category: category_id,
    } = this.props.match.params;

    section = this.selectSection(sections, section_id);
    program = await this.selectBaseProgram(program_id);

    this.setState({ sections, error, section, program, category });
  }

  async selectBaseProgram(program_id) {
    if (!program_id) return false;
    const programs = await BaseProgramService.getAll();
    if (!Array.isArray(programs)) return false;
    program_id = parseInt(program_id);
    console.debug("programs", programs);
    console.debug(
      "found program",
      programs.find((p) => p.base_program_id === program_id)
    );
    return programs.find((p) => p.base_program_id === program_id);
  }

  selectSection(sections, section_id) {
    if (section_id && Array.isArray(sections)) {
      return sections.find((s) => s.section_id === section_id);
    }
    return false;
  }

  render() {
    const { section_id, program_id, category } = this.props.match.params;
    const { sections, section, program, error } = this.state;

    const rows = Array.isArray(sections)
      ? sections.map((s) => ({ ...s, id: s.section_id }))
      : [];

    console.debug("Sections", rows);

    let breadcrumbs = [
      {
        to: "/",
        icon: <Home />,
      },
      {
        to: "/sections",
        text: "Sections",
      },
    ];

    if (program_id && program === null) return <CircularProgress />;

    if (error) return <ErrorMessage error={error} />;

    // if section_id is present, add it to breadcrumbs
    if (section_id) {
      breadcrumbs.push({
        to: `/sections/${section_id}`,
        text: section_id,
      });
    }

    if (program_id && program) {
      breadcrumbs.push({
        to: `/sections/${section_id}/${program_id}`,
        text: program.base_program_title,
      });
    }

    if (category) {
      breadcrumbs.push({
        to: `/sections/${section_id}/${program_id}/${category}`,
        text: category,
      });
    }

    // if (section_id && !program_id)
    //   return <Section {...{ sections, section_id }} />;

    // if (section_id && program_id && !category)
    //   return <SectionProgram {...{ sections, section_id, program_id }} />;

    // if (section_id && program_id && category)
    //   return (
    //     <SectionProgramCategory {...{ section_id, program_id, category }} />
    //   );

    return (
      <div className="sections">
        <AutoBreadcrumbs items={breadcrumbs} />
        {
          // if only section id is passed, show single section content
          section_id && !program_id && <Section {...{ section, sections }} />
        }
        {
          // if program id is passed, show single program content
          section_id && program_id && !category && (
            <SectionProgram
              {...{ section, program_id, program }}
              onDataUpdate={() => this.fetchData(true)}
            />
          )
        }
        {
          // if category is passed, show single category content
          section_id && program_id && category && (
            <SectionProgramCategory
              {...{
                section_id,
                program_id,
                category_slug: category,
                sections,
                program,
              }}
            />
          )
        }
        {
          // if no section id is passed, show all sections
          !section_id && !program_id && !category && (
            <AdminTable
              {...{ columns, rows }}
              autoHeight
              searchComponent={() => <></>}
              buttons={[]}
            />
          )
        }
      </div>
    );
  }
}

export default Sections;
