import axios from "axios";
import store from "../redux/store";
import Actions from "../redux/actions";
import A from "../redux/actionTypes";
import { notify } from "../redux/reduxFunctions";

const baseURL = process.env.REACT_APP_API;
/**
 * A base client-side service class with maneagable cache and axios as .api property.
 * To create a descendant:
 * class NewClass extends baseClientService{
 *  ...
 * }
 *
 * baseClientService.extend(NewClass) // this effectively copies the properties and methods
 *
 */
class baseClientService {
  static cache(index, value) {
    this._CACHE[index] = value;
  }

  static getCached(index, defaultValue) {
    return this.cacheExists(index)
      ? this.Copy(this._CACHE[index])
      : defaultValue;
  }

  /**
   * Returns a copy of cached value or calls the callback with cache index as parameter
   * @param {*} index
   * @param {*} callback
   */
  static async getCachedOr(index, callback) {
    return this.cacheExists(index)
      ? this.Copy(this._CACHE[index])
      : await callback(index);
  }

  static cacheExists(index) {
    return this._CACHE.hasOwnProperty(index);
  }

  static clearCache(index) {
    if (index) {
      if (this.cacheExists(index)) {
        delete this._CACHE[index];
      }
    } else {
      this._CACHE = {};
    }
  }
  static Copy(x) {
    return JSON.parse(JSON.stringify(x));
  }

  static printCache() {
    console.debug("\n\n\nCache:\n", this._CACHE);
  }

  static Error(message) {
    this.error = message;
  }

  /**
   * Usage: baseClientService.extend(descendantClass)
   * @param {*} descendant
   */
  static extend(descendant) {
    Object.assign(descendant, this);

    // re-create static data variables for extended class
    descendant._CACHE = { ...{ ...this._CACHE } };
  }

  static prepareConfig(config = {}) {
    // just in case...
    config = typeof config === "object" ? config : {};
    // get authorization header from redux state
    const { authorization } = store.getState().auth;

    // get headers from passed config, if any
    let { headers = {} } = config;
    // add authorization header
    headers = { ...headers, authorization };

    return { ...config, headers };
  }

  /**
   * Simple wrapper for axios get() method that adds authorization header from redux state.
   * Will throw error on request error.
   * @param {*} url
   * @param {*} config
   */
  static async GET(url, config = {}) {
    try {
      const { data } = await baseClientService.api.get(
        url,
        this.prepareConfig(config)
      );
      return data;
    } catch (e) {
      this.dealWithCathedError(e);
      // // console.log( "Error: ", e.requestData )
      // console.log(e.response);
      // if (! e.response) {
      //   throw e.message;
      // }
      
      // if (e.response.status === 401) {
      //   throw e.response.data;
      // }

      // // oops, session terminated
      // // logout
      // this.dispatch(A.LOGOUT);
      // // show message
      // this.dispatch(A.SHOW_MESSAGE, {
      //   message: "Your session has expired, please log in again",
      //   type: "warning",
      // });
    }
  }

  /**
   * Simple wrapper for axios post() method that adds authorization header from redux state. Will throw error on request error.
   * @param {*} url
   * @param {*} requestData
   * @param {*} config
   */
  static async POST(url, requestData = {}, config = {}) {
    try {
      const result = await baseClientService.api.post(
        url,
        requestData,
        this.prepareConfig(config)
      );
      console.log("POST result:", result);
      const { data } = result;
      console.log("POST data:", data);
      return data;
    } catch (e) {
      // console.log( "Error: ", e.requestData )
      this.dealWithCathedError(e);
    }
  }

  static dealWithCathedError(e) {
    console.debug("Dealing with catched error")
    if (! e.response) {
      throw e.message;
    }
    // if (e.response) {
    console.debug(e.response);

    switch (e.response.status) {
      case 401:
        // oops, either session terminated or authentication failed
        // logout, in case session was terminated
        this.dispatch(A.LOGOUT);
        notify(e.response.data || `Status: ${e.response.statusText}`, "warning")
        break;
      // in future probably deal with other special responses here
      default:
        break;
    }

    // show message from response
    throw e.response.data || `Status: ${e.response.statusText}`;
   
  }

  static dispatch(type, payload) {
    return store.dispatch({ type, payload });
  }

  static action(actionName, actionArguments) {
    try {
      return Actions[actionName].apply(null, actionArguments);
    } catch (e) {
      console.error(`Error in action ${actionName}: `, e.message);
      return false;
    }
  }
}

baseClientService._CACHE = {};
baseClientService.api = axios.create({
  baseURL,
});

// console.debug("API:",baseClientService.api)

// action types
baseClientService.actionTypes = A;

export default baseClientService;
