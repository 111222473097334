import baseClientService from "./baseClientService";

class TranslationService extends baseClientService {
  static async getLanguages( forceReload = false ) {
    const cacheIndex = "all-languages";
    
    if( forceReload ){
      // clear cache under index
      this.clearCache(cacheIndex);
    }
    return this.getCachedOr( cacheIndex, async () => {
      try {
        const result = await this.GET("/admin/translations/languages");
        this.cache(cacheIndex, result);
        return result;
      } catch (e) {
        this.Error("Error fetching languages: " + e.message);
        return false;
      }
    });
  }

  /**
   * Returns language by language id (iso639_1 value) or false if not found
   * @param {*} language_id 
   * @returns {object|false}
   */
  static async getLanguage( language_id ){
    const languages = await this.getLanguages();
    if( Array.isArray(languages)) return languages.find( l => l.iso639_1 === language_id )

    return false;
  }

  static async getSections(forceReload = false) {
    const cacheIndex = "all-sections";

    const fetchFromServer = async () => {
      try {
        const result = await this.GET("/admin/translations/sections");
        this.cache(cacheIndex, result);
        return this.getCached(cacheIndex);
      } catch (e) {
        this.Error("Error fetching sections: " + e.message);
        return false;
      }
    };

    // forced reload - get from server and cache
    if (forceReload) return fetchFromServer();

    // not forced reload - get from cache if exists, otherwise get from server
    return this.getCachedOr(cacheIndex, fetchFromServer);
  }

  static async getPrograms(forceReload = false) {
    const cacheIndex = "all-programs";

    const fetchFromServer = async () => {
      try {
        const result = await this.GET("/admin/translations/programs");
        this.cache(cacheIndex, result);
        return this.getCached(cacheIndex);
      } catch (e) {
        return this.Error("Error fetching programs: " + e.message);
      }
    };

    // forced reload - get from server and cache
    if (forceReload) return fetchFromServer();

    // not forced reload - get from cache if exists, otherwise get from server
    return this.getCachedOr(cacheIndex, fetchFromServer);
  }

  static async getCategoryContent(content_id, forceReload = false) {
    const cacheIndex = `content-${content_id}`;

    const fetchFromServer = async () => {
      try {
        const result = await this.GET(
          "/admin/translations/content/" + content_id
        );
        this.cache(cacheIndex, result);
        return this.getCached(cacheIndex);
      } catch (e) {
        return this.Error("Error fetching content: " + e.message);
      }
    };

    // forced reload - get from server and cache
    if (forceReload) return fetchFromServer();

    // not forced reload - get from cache if exists, otherwise get from server
    return this.getCachedOr(cacheIndex, fetchFromServer);
  }

  static async updateTranslation(content_id, language_id, html) {
    try {
      return await this.POST(
        `/admin/translations/content/${content_id}/${language_id}`,
        { html }
      );
    } catch (e) {
      return this.Error("Error updating content: " + e);
    }
  }

  static async createTranslation(content_id, language_id) {
    try {
      return await this.POST(
        `/admin/translations/content/${content_id}/${language_id}/create`
      );
    } catch (e) {
      return this.Error("Error creating content: " + e);
    }
  }

  static async deleteTranslation(content_id, language_id) {
    try {
      return await this.POST(
        `/admin/translations/content/${content_id}/${language_id}/delete`
      );
    } catch (e) {
      return this.Error("Error deleting content: " + e);
    }
  }
}

baseClientService.extend(TranslationService);

export default TranslationService;
