import baseClientService from "./baseClientService";

class LanguageService extends baseClientService {
  
  /**
   * Returns the complete list of ISO languages
   * @returns 
   */
  static async getIsoList() {
    
    const index = "all-iso-languages";
    return this.getCachedOr( index, 
      async() => {
        try {

          const result = await this.GET(`/admin/language/iso-list`);
          this.cache(index, result );
          return this.getCached(index);

        } catch (e) {
          return this.Error("Error fetching content: ", e.message);
        }
      }
    )
  }

  static async addLanguage(data){
    try {

      await this.POST(`/admin/language/add`, data );
      return true;

    } catch (e) {
      return this.Error(e.message || "Unknown error");
    }
  }
  static async updateLanguage(language_id, data ){
    try {

      await this.POST(`/admin/language/update/${language_id}`, data);
      return true;

    } catch (e) {
      return this.Error(e.message || "Unknown error");
    }
  }
  static async deleteLanguage(language_id){
    try {

      await this.POST(`/admin/language/delete/${language_id}`);
      return true;

    } catch (e) {
      return this.Error(e.message || "Unknown error");
    }
  }
}

baseClientService.extend(LanguageService);

export default LanguageService;
