import baseClientService from "./baseClientService";

const allSectionsCacheId = "all-sections";

class SectionService extends baseClientService {
  static async getAll(forceReload = false) {
    const fetchSections = async (cacheId) => {
      try {
        const sections = await this.GET("/admin/sections/all");
        if (sections) {
          this.cache(cacheId, sections);
          return sections;
        }
        throw new Error(this.error || "Unknown error");
      } catch (e) {
        return this.Error(e.message);
      }
    };

    // if force reload - just do it;
    if (forceReload) return await fetchSections(allSectionsCacheId);

    return await this.getCachedOr(allSectionsCacheId, fetchSections);
  }

  /**
   * Sends request to rewrite completely section folder content in target BlueFox folder
   * @param {*} section_id
   * @returns
   */
  static async rewriteSectionFiles(section_id) {
    try {
      await this.POST(`/admin/sections/rewrite-section-files/${section_id}`);
      return true;
    } catch (e) {
      console.debug(e);
      return this.Error(e || "Unknown server error");
    }
  }

  static async getSectionHtml(section_id) {
    try {
      const { html } = await this.GET(`/admin/sections/html/${section_id}`);
      return html;
    } catch (e) {
      console.debug("getSectionHtml error:\n", e);
      return this.Error(e || "Unknown server error");
    }
  }
}

baseClientService.extend(SectionService);

export default SectionService;
