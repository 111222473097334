import baseClientService from "./baseClientService";

const allProgramsCacheId = "all-programs";
class ProgramService extends baseClientService {
  static async getAllPrograms(forceReload = false) {
    const fetchPrograms = async (cacheId) => {
      try {
        console.debug("getAllPrograms: fetching programs from server");
        const { programs } = await this.GET("/admin/programs/all");
        if (programs) {
          this.cache(cacheId, programs);
          return programs;
        }
        this.Error("Unknown error: ", this.error);
        return false;
      } catch (e) {
        this.Error(e.message);
        return false;
      }
    };

    // if force reload - just do it;
    if (forceReload) return await fetchPrograms(allProgramsCacheId);
    console.debug("getAllPrograms: fetching programs from CACHE");

    return await this.getCachedOr(allProgramsCacheId, fetchPrograms);
  }

  static async getTypes() {
    // TODO: fetch from DB
    const types = {
      playandexplore: { title: "Play&Explore Programs" },
      runandcode: { title: "Run&Code Examples" },
    };

    return types;
  }

  static async getCategories() {
    try {
      const { categories } = await this.GET("/admin/programs/category-list");
      return categories;
    } catch (e) {
      this.Error(e.message);
      return false;
    }
  }

  static async addProgram(title, type, slug, program_category = 4) {
    try {
      const { program } = await this.POST("/admin/programs/add", {
        title,
        type,
        slug,
        program_category,
      });
      if (program) {
        return program;
      }
      this.Error(this.error || "Unknown error adding program");
      return false;
    } catch (e) {
      this.Error(e.message);
      return false;
    }
  }

  static async updateProgram(program_id, data) {
    try {
      const { program } = await this.POST(
        `/admin/programs/${program_id}/update`,
        data
      );
      if (program) {
        // clear cache
        this.clearCache();
        return program;
      }
      this.Error(this.error || "Unknown error updating program");
      return false;
    } catch (e) {
      this.Error(e.message);
      return false;
    }
  }

  static async updateTranslation(program_id, lang, text) {
    try {
      const result = await this.POST(
        `/admin/programs/update-text/${program_id}/${lang}`,
        { text }
      );
      // clear cache
      this.clearCache();
      return result;
    } catch (e) {
      this.Error(e.message);
      return false;
    }
  }

  static async updateTitle(program_id, lang, title) {
    try {
      const result = await this.POST(
        `/admin/programs/update-title/${program_id}/${lang}`,
        { title }
      );
      // clear cache
      this.clearCache();
      return result;
    } catch (e) {
      this.Error(e.message);
      return false;
    }
  }

  static async updateTitleAndText(program_id, lang, title, text) {
    try {
      const result = await this.POST(
        `/admin/programs/update-title-and-text/${program_id}/${lang}`,
        { title, text }
      );
      // clear cache
      this.clearCache();
      return result;
    } catch (e) {
      this.Error(e.message);
      return false;
    }
  }

  static async uploadImage(file) {
    try {
      const result = await this.POST("/admin/media/upload-image", file);
      // clear cache
      this.clearCache();
      return result;
    } catch (e) {
      this.Error("Error uploading image: ", e.message);
      return false;
    }
  }

  static async uploadImageToProgram(file, program_id) {
    try {
      const result = await this.POST(`/admin/media/upload/${program_id}`, file);
      // clear cache
      this.clearCache();
      return result;
    } catch (e) {
      this.Error("Error uploading image: ", e.message);
      return false;
    }
  }
}

ProgramService.extend(baseClientService);

export default ProgramService;
