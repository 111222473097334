import { Home, Person, Lock } from "@material-ui/icons";
import React from "react";
import AutoBreadcrumbs from "../template/AutoBreadcrumbs";
import AuthService from "../../services/auth";
import "./style.scss";
import { Button } from "@material-ui/core";
import PasswordChangeForm from "./password-change-form";
import ProcessSpinner from "../template/ProcessSpinner";
import { notify } from "../../redux/reduxFunctions";
import store from "../../redux/store";

const DetailRow = ({ title, value }) => (
  <div className="flex row detail-row">
    <span>{title}</span>
    <span>{value}</span>
  </div>
);

class UserProfile extends React.Component {
  state = {
    showChangePasswordForm: false,
    processSpinnerHeader: null,
  };

  changePassword = (password) => {
    this.setState(
      // first - show process spinner
      { processSpinnerHeader: "Changing password..." },
      async () => {
        try {
          await AuthService.changePassword(password);
          this.setState({
            processSpinnerHeader: null,
            showChangePasswordForm: false,
          });
          notify("Password changed successfully", "success");
        } catch (e) {
          notify(e.message || e || "Error changing password");
          // remove process spinner
          this.setState({ processSpinnerHeader: null });
        }
      }
    );
  };
  render() {
    const { processSpinnerHeader, showChangePasswordForm } = this.state;
    const { user } = store.getState().auth || {};
    const breadcrumbs = [
      {
        to: "/",
        icon: <Home />,
      },
      {
        to: "/profile",
        icon: <Person />,
        text: "My profile",
      },
    ];

    return (
      <div className="user-profile">
        <AutoBreadcrumbs items={breadcrumbs} />
        <h1>User profile</h1>
        <div className="cell-container">
          <div className="gray-frame cell flex column grow1 ">
            <h2>Details</h2>
            <DetailRow title="Name" value={user.name} />
            <DetailRow title="Email" value={user.email} />
            <DetailRow
              title="Status"
              value={user.active ? "Active" : "Not active"}
            />
          </div>
          <div className="gray-frame cell flex column grow1 ">
            <h2>Credentials</h2>
            {user?.credential_titles?.map((c) => (
              <DetailRow title={c} />
            ))}
          </div>
          <div className="gray-frame cell grow1">
            <h2>Security</h2>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({ showChangePasswordForm: true })}
            >
              <Lock /> Change password
            </Button>
          </div>
        </div>

        <PasswordChangeForm
          open={showChangePasswordForm}
          onClose={() => this.setState({ showChangePasswordForm: false })}
          onSubmit={this.changePassword}
        />

        <ProcessSpinner
          header={processSpinnerHeader}
          open={!!processSpinnerHeader}
        />
      </div>
    );
  }
}

export default UserProfile;
