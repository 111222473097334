import React from "react";
import Editor from "@bit/ses-education.ses-courses-shared-components.tinymce-editor";

import "./plugins/add-icon";
import "./plugins/alignment";
import "./plugins/run-and-code-blocks";

import { addIconsButtons, addIconsFormats } from "./plugins/add-icon";
import {
  addButtons as addButtonsRunAndCode,
  addFormats as addFormatsRunAndCode,
} from "./plugins/run-and-code-blocks";
import {
  addButtons as addButtonsPlayAndExplore,
  addFormats as addFormatsPlayAndExplore,
} from "./plugins/play-and-explore-blocks";
import {
  addIconsButtons as AlignmentButtons,
  addIconsFormats as AlignmentFormats,
} from "./plugins/alignment";
import Service from "../../services/programService";
import "./program-editor.scss";
import { Button, ButtonGroup } from "@material-ui/core";
import Display from "./Display";
import AuthActions from "../../redux/auth-actions";

let userInteractionSimulationTimer;

class ProgramContentEditor extends React.Component {
  state = {
    editing: false,
    editorContainerClass: "runandcode", // playandexplore|runandcode - play: 560px, code: 100%
  };

  componentDidMount() {
    console.debug("ProgramContentEditor started");
    const { program_type: editorContainerClass } = this.props;
    this.setState({ editorContainerClass });

    // When user is working within editor's iframe, there're no user interaction event happen in UI,
    // hence when the session time runs out and token is due to renew - the program may decide
    // that the user is absent and log them out.
    // To prevent this, we set a timer to simulate UI interaction all the time while the component is up
    userInteractionSimulationTimer = setInterval(
      // update UI usage timestamp
      () => AuthActions.updateUIUsageTimestamp(),
      1000 * 60 // will simulate one user interaction per minute, it should
      // 1000 // testing
    );
  }

  componentWillUnmount() {
    clearInterval(userInteractionSimulationTimer);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.containerWidth !== this.props.containerWidth) {
      this.forceUpdate();
    }

    if (prevProps.program_type !== this.props.program_type) {
      this.setState({ editorContainerClass: this.props.program_type });
    }
  }

  /**
   * Prepares init object for the editor.
   * Needs program id for the file picker script that uses
   * ajax call to program-id-based URL.
   * @param {*} program_id
   */
  prepateEditorInit(program_id) {
    const { program_type, containerWidth } = this.props;

    const initByType = {
      playandexplore: {
        formats: {
          ...addIconsFormats,
          ...AlignmentFormats,
          ...addFormatsPlayAndExplore,
        },
        plugins: ["add-icon", "alignment", "play-and-explore-blocks"],
        width: 1502,
        height: 838,
        toolbar2: `${addIconsButtons} | ${addButtonsPlayAndExplore}`,

        // [
        //   {
        //     name: "Icons",
        //     items: [`${addIconsButtons}`],
        //   },
        //   {
        //     name: "Special items",
        //     items: [`${addButtonsPlayAndExplore}`],
        //   },
        // ],
      },
      runandcode: {
        formats: {
          ...addIconsFormats,
          ...AlignmentFormats,
          ...addFormatsRunAndCode,
        },
        plugins: ["add-icon", "alignment", "run-and-code-blocks"],
        width: containerWidth, // 550,
        height: 847,
        toolbar2: `${addIconsButtons} | ${addButtonsRunAndCode}`,
      },
    };

    return {
      // formats: { ...addIconsFormats, ...IconFormats2, ...addFormats },
      // plugins: ["add-icon", "alignment", "run-and-code-blocks"],

      // override and use several toolbars via toolbar(n) option
      toolbar1: `undo redo | formatselect | bold italic backcolor | \
            image \ ${AlignmentButtons} \ |
            bullist numlist outdent indent | removeformat | help `,

      // alignleft aligncenter alignright alignjustify | \
      content_css: "/css/custom-content.css",

      // add filepicker
      file_picker_callback: (cb, value, meta) => {
        let input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");

        input.onchange = function () {
          // get the file from files
          let file = this.files[0];

          let reader = new FileReader();

          reader.onload = async function () {
            // prepare form data with needed name
            const data = new FormData();
            data.append("image", file);

            // send form data
            // const result = await Service.uploadImage(data);
            const result = await Service.uploadImageToProgram(data, program_id);
            if (result && result.url) {
              const { url } = result;
              cb(url, { title: file.name });
              return;
            }
            // else {

            // TODO: show a message
            console.log("IMAGE UPLOAD FAILED, result: ", result);

            // }
          };
          reader.readAsDataURL(file);
        };
        input.click();
      },

      // run an additional script after init
      init_instance_callback: (editor) => {
        // add scrolling listener
        editor.on("ScrollContent", (e) => {
          // console.log("ScrollContent", e.target.documentElement.scrollTop);
          const doc = e.target?.documentElement || {};
          const { scrollTop } = doc;
          const body = doc.getElementsByTagName("body")[0];
          // console.debug("body:", body);
          const isScrolled = body?.classList?.contains("scrolled");
          if (scrollTop > 0 && !isScrolled) {
            console.debug("marking as scrolled");
            body && body.classList.add("scrolled");
          } else if (scrollTop === 0 && isScrolled) {
            console.debug("marking as not scrolled");
            body && body.classList.remove("scrolled");
          }
        });
      },

      // mixin settings relevant to each program type
      ...(initByType[program_type] || {}),
    };
  }

  onSave = async (html) => {
    const { onSave } = this.props;
    if (!onSave || typeof onSave !== "function") {
      console.debug("onSave callback not set");
      return; // quit
    }
    // TODO: block the editor while saving and unblock after getting the result
    const saved = await onSave(html);
    if (saved) {
      alert("HTML saved");
    }
  };

  render() {
    const { editing, editorContainerClass, containerWidth = 550 } = this.state;
    const {
      html = "",
      title,
      slug,
      program_id,
      program_type,
      category_slug,
      lang = "en",
    } = this.props;

    console.debug("props:", this.props);

    const editorInit = this.prepateEditorInit(program_id);

    if (editing) {
      return (
        <>
          {/* <div className="buttons">
            <Button
              variant="contained"
              size="medium"
              // color="secondary"
              className="width-switch"
              onClick={() =>
                this.setState({ editorContainerClass: "playandexplore" })
              }
            >
              Width: Play & explore
            </Button>
            <Button
              variant="contained"
              size="medium"
              // color="secondary"
              className="width-switch"
              onClick={() =>
                this.setState({ editorContainerClass: "runandcode" })
              }
            >
              Width: Run & code
            </Button>
          </div> */}

          <div
            className={`editor-container ${editorContainerClass}`}
            style={{ width: `calc(${containerWidth}px + 4em)` }}
          >
            <Editor
              value={html}
              onSave={this.onSave}
              onCancel={() => this.setState({ editing: false })}
              init={{
                ...editorInit,
                width: containerWidth,
                body_class: `type-${program_type} category-${slug}`,
              }}
              // init = {this.init}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <h2>{title}</h2>

        <div className="buttons">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => this.setState({ editing: true })}
          >
            Edit content
          </Button>
          <ButtonGroup className="variant-select">
            <Button onClick={() => this.setState({ containerWidth: 550 })}>
              Narrow window
            </Button>
            <Button onClick={() => this.setState({ containerWidth: 1502 })}>
              Wide window
            </Button>
          </ButtonGroup>
        </div>
        <div
          className={`editor-container ${editorContainerClass}`}
          style={{ width: `${containerWidth}px` }}
        >
          <Display {...{ html, slug, program_type, lang, program_id }} />
          {/* <div dangerouslySetInnerHTML={{__html: html}}></div> */}
        </div>
      </>
    );
  }
}

export default ProgramContentEditor;
