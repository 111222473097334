/**
 * This file contains various configs for tinymce editor.
 * Add new configs here and import it from here to keep things tidy.
 */

/**
 * default editor at Courses level.
 * Allows to paste images and upload images to /media/courses
 */
export const defaultCoursesConfig = {
  relative_urls: false,

  content_css: "/css/custom-content.css",

  // paste images
  paste_data_images: true,

  // add filepicker that uploads to /media/courses/
  file_picker_callback: function (cb, value, meta) {
    let input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.onchange = function () {
      // get the file from files
      let file = this.files[0];

      let reader = new FileReader();

      reader.onload = async function () {
        // prepare form data with needed name
        const data = new FormData();
        data.append("image", file);

        // send form data
        // const result = await ExperimentsService.uploadImage(data);
        // if (result && result.url) {
        //   const { url } = result;
        //   cb(url, { title: file.name });
        // } else {
        //   // TODO: show a message
        //   console.log("IMAGE UPLOAD FAILED, result: ", result);
        // }
      };
      reader.readAsDataURL(file);
    };
    input.click();
  },

  //make file picker available
  file_picker_types: "image file media",
  image_title: true,

  automatic_uploads: true,
  selector: "textarea",
  formats: {
    topline: { inline: "span", classes: "top-line", remove: "none" },
  },
  style_formats: [
    { title: "Top Line", format: "topline" },
    {
      title: "Headers",
      items: [
        { title: "Header 1", format: "h1" },
        { title: "Header 2", format: "h2" },
        { title: "Header 3", format: "h3" },
        { title: "Header 4", format: "h4" },
        { title: "Header 5", format: "h5" },
        { title: "Header 6", format: "h6" },
      ],
    },
    {
      title: "Inline",
      items: [
        { title: "Bold", icon: "bold", format: "bold" },
        { title: "Italic", icon: "italic", format: "italic" },
        { title: "Underline", icon: "underline", format: "underline" },
        {
          title: "Strikethrough",
          icon: "strikethrough",
          format: "strikethrough",
        },
        { title: "Superscript", icon: "superscript", format: "superscript" },
        { title: "Subscript", icon: "subscript", format: "subscript" },
        { title: "Code", icon: "code", format: "code" },
      ],
    },
    {
      title: "Blocks",
      items: [
        { title: "Paragraph", format: "p" },
        { title: "Blockquote", format: "blockquote" },
        { title: "Div", format: "div" },
        { title: "Pre", format: "pre" },
      ],
    },
    {
      title: "Alignment",
      items: [
        { title: "Left", icon: "alignleft", format: "alignleft" },
        { title: "Center", icon: "aligncenter", format: "aligncenter" },
        { title: "Right", icon: "alignright", format: "alignright" },
        { title: "Justify", icon: "alignjustify", format: "alignjustify" },
      ],
    },
  ],
  style_format_merge: true,
};

/**
 * Distraction-free editor, no menu, right-click menu to edit text, etc.
 */
export const distractionFreeEditor = {
  relative_urls: false,

  menubar: false,
  toolbar: false,

  content_css: "/css/custom-content.css",

  // paste images
  paste_data_images: true,

  image_title: true,

  selector: "textarea",
  formats: {
    topline: { inline: "span", classes: "top-line", remove: "none" },
  },
  style_formats: [
    { title: "Top Line", format: "topline" },
    {
      title: "Headers",
      items: [
        { title: "Header 1", format: "h1" },
        { title: "Header 2", format: "h2" },
        { title: "Header 3", format: "h3" },
        { title: "Header 4", format: "h4" },
        { title: "Header 5", format: "h5" },
        { title: "Header 6", format: "h6" },
      ],
    },
    {
      title: "Inline",
      items: [
        { title: "Bold", icon: "bold", format: "bold" },
        { title: "Italic", icon: "italic", format: "italic" },
        { title: "Underline", icon: "underline", format: "underline" },
        {
          title: "Strikethrough",
          icon: "strikethrough",
          format: "strikethrough",
        },
        { title: "Superscript", icon: "superscript", format: "superscript" },
        { title: "Subscript", icon: "subscript", format: "subscript" },
        { title: "Code", icon: "code", format: "code" },
      ],
    },
    {
      title: "Blocks",
      items: [
        { title: "Paragraph", format: "p" },
        { title: "Blockquote", format: "blockquote" },
        { title: "Div", format: "div" },
        { title: "Pre", format: "pre" },
      ],
    },
    {
      title: "Alignment",
      items: [
        { title: "Left", icon: "alignleft", format: "alignleft" },
        { title: "Center", icon: "aligncenter", format: "aligncenter" },
        { title: "Right", icon: "alignright", format: "alignright" },
        { title: "Justify", icon: "alignjustify", format: "alignjustify" },
      ],
    },
  ],
  style_format_merge: true,
};

/**
 * Allows to add/edit Questions
 */
export const questionEditor = {
  // base on the default courses config
  ...defaultCoursesConfig,

  // custom CSS for editor's iframe content
  content_css: "/css/custom-content.css",

  // add questions to context menu
  contextmenu: "question quiz color multiple",

  // add questions to plugins and toolbar
  // plugins: ["question quiz color multiple"],
  // toolbar: ["question quiz color multiple"],
};
