import React from "react";
import { Button, TextField } from "@material-ui/core";
import { PersonRounded as User } from "@material-ui/icons";
import Service from "../../services/auth";
import { notify } from "../../redux/reduxFunctions";
import Notifications from "../template/notifications/bind-redux";

class Login extends React.Component {
  state = {
    email: "",
    pass: "",
  };

  onChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  login = async (ev) => {
    ev.preventDefault();
    const { email, pass } = this.state;
    const result = await Service.login(email, pass);
    console.debug(result);
    if (result) {
      Service.dispatch("LOGIN", result);
      return;
    }

    notify(Service.error || "Unknown error");
  };

  render() {
    const { email, pass } = this.state;

    return (
      <div className="flex column items-center grow1 content-center">
        <form className="login-form" onSubmit={this.login}>
          <h1>SENSE data admin</h1>
          <div className="flex row align-center">
            <User /> Login
          </div>
          <div className="form-content flex column items-center">
            <TextField
              name="email"
              value={email}
              placeholder="email"
              onChange={this.onChange}
            />
            <TextField
              name="pass"
              type="password"
              placeholder="******"
              value={pass}
              onChange={this.onChange}
            />
            <Button variant="contained" color="primary" type="submit">
              Login
            </Button>
          </div>
          <Notifications />
        </form>
      </div>
    );
  }
}

export default Login;
