import React from "react";
import {
  Button,
  CircularProgress,
  // List, ListItem
} from "@material-ui/core";
// import { Link } from "react-router-dom";
// import AdminTable from "../template/admin-table";
// import BaseProgramService from "../../services/base-program-service";
import TranslationService from "../../services/translationService";
import TranslationDesk from "./translation-desk";
import {
  Add,
  // Delete
} from "@material-ui/icons";
import { notify } from "../../redux/reduxFunctions";
import { Alert } from "@material-ui/lab";
import AuthActions from "../../redux/auth-actions";

let userInteractionSimulationTimer;

class TranslationsProgramCategory extends React.Component {
  state = {
    content: null,
    translationDirectionality: "ltr"
  };

  componentDidMount() {
    this.fetchData();
  }

  async fetchData(forceReload = false) {
    const { program, section, category, language_id } = this.props;
    // console.debug("TranslationsProgramCategory", program, section, category);
    const content = await TranslationService.getCategoryContent(
      category.content_id,
      forceReload
    );

    // fetch language directionality
    const translationDirectionality = await this.fetchDirectionality(language_id);
    

    this.setState({ content, translationDirectionality });

    

    // When user is working within editor's iframe, there're no user interaction event happen in UI,
    // hence when the session time runs out and token is due to renew - the program may decide
    // that the user is absent and log them out.
    // To prevent this, we set a timer to simulate UI interaction all the time while the component is up
    userInteractionSimulationTimer = setInterval(
      // update UI usage timestamp
      () => AuthActions.updateUIUsageTimestamp(),
      1000 * 60 // will simulate one user interaction per minute, it should
      // 1000 // testing
    );
  }


  async componentDidUpdate(prevProps){
    if( this.props.language_id && prevProps.language_id !== this.props.language_id ){
      // if language has changed - fetch directionality
      const translationDirectionality = await this.fetchDirectionality(this.props.language_id);
      this.setState({translationDirectionality});
    }
  }

  async fetchDirectionality(language_id){
    const lang = await TranslationService.getLanguage(language_id);
    console.debug("fetchDirectionality language:", lang );
    return typeof lang === "object" ? lang.direction : "ltr";    
  }

  componentWillUnmount() {
    clearInterval(userInteractionSimulationTimer);
  }

  onUpdate = async (value) => {
    if (
      !(await TranslationService.updateTranslation(
        this.state.content.program_id,
        this.props.language_id,
        value
      ))
    ) {
      notify(TranslationService.error || "Unknown error");
    }
    notify("Translation was successfully saved", "success");
    this.fetchData(true);
  };

  createTranslation = async () => {
    const { content } = this.state;
    const { language_id } = this.props;

    if (!content) {
      notify("No content found");
      return;
    }

    if (
      !(await TranslationService.createTranslation(
        content.program_id,
        language_id
      ))
    ) {
      notify(TranslationService.error || "Unknown error");
      return;
    }

    this.fetchData(true);
  };

  deleteTranslation = async () => {
    const { content } = this.state;
    const { language_id } = this.props;
    if (
      !(await TranslationService.deleteTranslation(
        content.program_id,
        language_id
      ))
    ) {
      // failed
      notify(TranslationService.error || "Unknown error");
      return;
    }
    // success - reload data
    this.fetchData(true);
  };

  render() {
    const { program, section, category, language_id } = this.props;
    // console.debug("TranslationsProgramCategory", program, section, category);
    if (!program || !category) return "";

    // const { category_title } = category;
    const { content, translationDirectionality } = this.state;

    if (!content) return <CircularProgress />;

    const { program_type, category_slug } = content || {};

    return (
      <div className="program-category">
        {content.html[language_id] && (
          <>
            <TranslationDesk
              originalValue={content.html["en"] || "No original content found"}
              originalLanguage="en"
              translationLanguage={language_id}
              value={content.html[language_id] || ""}
              bodyClass={`type-${program_type} category-${category_slug}`}
              type="html"
              onUpdate={this.onUpdate}
              onDelete={this.deleteTranslation}
              title={`Program '${program.base_program_title}', chapter '${category.category_title}'`}
              translationDirectionality={translationDirectionality}
            />
          </>
        )}
        {!content.html[language_id] && (
          <>
            <Alert severity="warning" className="m-bottom-10">
              There's no translation here yet. Use "Create translation" button
              to create one.
            </Alert>
            <Button
              variant="contained"
              color="primary"
              onClick={this.createTranslation}
            >
              <Add /> Create translation
            </Button>
          </>
        )}
      </div>
    );
  }
}

export default TranslationsProgramCategory;
