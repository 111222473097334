import React from "react";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import AdminTable from "../template/admin-table";
import ErrorMessage from "../template/ErrorMessage";
import ConfirmDialog from "../template/ConfirmDialog";
import "./style.scss";
import ModalWindow from "../template/modal-dialog";
import SectionProgramAssignContent from "./section-program-assign-content";
import ProgramService from "../../services/programService";
import BaseProgramService from "../../services/base-program-service";

class SectionProgram extends React.Component {
  state = {
    onConfirm: null,
    confirmHeader: null,
    confirmPrompt: null,
    assignDialogType: null,
    programs: null,
  };

  componentDidMount() {
    this.fetchPrograms();
  }

  componentDidUpdate(prevProps) {
    if (this.props.section !== prevProps.section) {
      this.fetchPrograms();
    }
  }

  async fetchPrograms() {
    const { section } = this.props;
    if (!section) return;
    let programs = await ProgramService.getAllPrograms();
    if (!Array.isArray(programs)) {
      this.setState({
        error: "Error loading programs from server, please reload page",
      });
    }
    // get only programs that might be linked to this section (of same program type )
    programs = programs.filter((p) => p.program_type === section.program_type);
    this.setState({ programs });
  }

  showDialog(onConfirm, confirmHeader, confirmPrompt) {
    this.setState({
      onConfirm,
      confirmHeader,
      confirmPrompt,
    });
  }

  /**
   * Remove content from program
   * @param {*} content_id
   * @returns
   */
  unlinkContent = async (content_id) => {
    // get data refresh callback
    const { onDataUpdate } = this.props;

    console.debug("SectionProgram unlinkContent", content_id);

    if (!(await BaseProgramService.unlinkContent(content_id))) {
      alert(
        "Could not unlink content from program: " + BaseProgramService.error ||
          "Unknown error"
      );
      return false;
    }

    // refresh data
    onDataUpdate();

    // close dialog
    this.showDialog(null, null, null);
    return true;
  };

  /**
   * Add content to program
   * @param {*} content_id
   * @returns
   */
  linkContent = async (content_id) => {
    if (
      !(await BaseProgramService.linkContent(
        this.props.program.base_program_id,
        content_id
      ))
    ) {
      alert(
        "Could not link content to program: ",
        BaseProgramService.error || "Unknown error"
      );
      return false;
    }

    return true;
  };

  /**
   * Create content and link it to program
   * @param {*} category_id
   * @returns
   */
  createContent = async (category_id) => {
    if (!category_id) {
      alert("Please select a category");
      return false;
    }
    if (
      !(await BaseProgramService.createContent(
        this.props.program.base_program_id,
        category_id
      ))
    ) {
      alert(
        "Could not link content to program: ",
        BaseProgramService.error || "Unknown error"
      );
      return false;
    }

    return true;
  };

  render() {
    const { program, section, onDataUpdate } = this.props;
    const {
      onConfirm,
      confirmHeader,
      confirmPrompt,
      assignDialogType,
      error,
      programs,
      moreMenuOpen,
    } = this.state;
    console.debug("SectionProgram program", program);

    if (program === null || programs === null) return <CircularProgress />;
    if (!program) return <ErrorMessage error="Program not found" />;
    if (error) return <ErrorMessage error={error} />;

    const { categories } = section;
    const rows = Array.isArray(categories)
      ? categories
          // add id field, required by DateTable
          ?.map((c) => ({
            ...c,
            id: c.category_id,
            content: program.content.find(
              (cn) => cn.content_category === c.category_id
            ),
          }))
          // sort by ordering
          ?.sort((a, b) => a.ordering - b.ordering)
      : [];

    console.debug("SectionProgram categories", rows);
    const columns = [
      {
        field: "id",
        hide: true,
      },
      {
        field: "category_title",
        headerName: "Category",
        width: 400,
      },
      {
        field: "content",
        headerName: "Content",
        width: 400,
        renderCell: (params) => {
          console.debug("SectionProgram renderCell", params);
          return (
            <ButtonGroup>
              {!params.row.content && (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    this.setState(
                      {
                        // pass category id to filter content
                        assignDialogType: params.row.category_id,
                      },
                      console.debug(this.state)
                    )
                  }
                >
                  Assign
                </Button>
              )}
              {
                // if content is linked, show unlink and edit buttons
                params.row.content && (
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/sections/${section?.section_id}/${program.base_program_id}/${params.row?.content?.category_slug}`}
                  >
                    Edit
                  </Button>
                )
              }
              {params.row.content && (
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    this.showDialog(
                      async () =>
                        await this.unlinkContent(
                          params.row?.content?.content_id
                        ),
                      "Unlink Content",
                      "Are you sure you want to unlink this content from this program?"
                    )
                  }
                >
                  Remove
                </Button>
              )}
            </ButtonGroup>
          );
        },
      },
    ];
    return (
      <div className="section-program">
        <Typography variant="h1">
          {program && program.base_program_title}
        </Typography>
        <AdminTable {...{ rows, columns }} autoHeight />
        <ConfirmDialog
          open={!!onConfirm}
          onClose={() => this.setState({ onConfirm: null })}
          onConfirm={onConfirm}
          header={confirmHeader}
          prompt={confirmPrompt}
        />
        <ModalWindow
          open={!!assignDialogType}
          onClose={() => this.setState({ assignDialogType: null })}
          header="Link Content"
        >
          <SectionProgramAssignContent
            {...{ programs, category_id: assignDialogType }}
            onConfirm={this.linkContent}
            onCreate={this.createContent}
            onClose={() =>
              this.setState({ assignDialogType: null }, onDataUpdate)
            }
          />
        </ModalWindow>
      </div>
    );
  }
}

export default SectionProgram;
