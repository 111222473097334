import { showMessage } from "../components/template/notifications/notificationActions";
import store from "./store";

// REDUX FUNCTIONS TO CALL FROM ANYWHERE

/**
 * Shows message in Notifications component
 * @param {string} message
 * @param {string} type - error|success|warning|info. Defaults to error
 */
function notify(message, type = "error") {
  showMessage(message, type)(store.dispatch);
}

export { notify };
