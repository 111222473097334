import React from "react";
import {
  Button,
  ButtonGroup,
  // Button,
  Card,
  CardActionArea,
  CardContent,
  // CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  // List,
  // ListItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";
// import AdminTable from "../template/admin-table";
// import BaseProgramService from "../../services/base-program-service";
import TranslationService from "../../services/translationService";
// import store from "../../redux/store";
import {connect} from "react-redux";
import { Add, Delete, Edit, MoreVert } from "@material-ui/icons";
import LanguageForm from "./language-form";
import ConfirmDialog from "../template/ConfirmDialog";
import LanguageService from "../../services/LanguageService";
import { notify } from "../../redux/reduxFunctions";

class TranslationsLanguageSelect extends React.Component {
  state = {
    languages: null,
    allowedLanguages: null,
    showLanguageForm: false,
    languageMenu: null,
    confirmDialogProps: null
  };


  buttonRefs = {}

  componentDidMount() {
    // add translator's languages to state, if any
    const {user} = this.props; //  store.getState().auth.user || {};

    // if user is a translator and is not an admin, restrict their languages to the set in config
    if (
      user.credentials.includes("translator") ||
      !user.credentials.includes("admin")
    ) {
      const allowedLanguages = Array.isArray(
        user?.config?.translator?.languages
      )
        ? [...user?.config?.translator?.languages]
        : [];
      return this.setState(
        {
          allowedLanguages,
        },
        () => this.fetchData()
      );
    }
    // user does not have language limitations, no restrictions is set
    this.fetchData();
  }

  async fetchData( forceReload = false ) {
    const { allowedLanguages } = this.state;
    let languages = await TranslationService.getLanguages(forceReload);
    if (!languages || !Array.isArray(languages)) {
      return this.setState({
        languages: null,
        error: TranslationService.error || "Unknown error",
      });
    }

    // remove english
    languages = languages.filter((l) => l.iso639_1 !== "en");

    // show only languages the user is allowed to see, if there is a limitation (allowedLanguages is set)
    if (allowedLanguages) {
      languages = languages.filter((l) =>
        allowedLanguages.includes(l.iso639_1)
      );
    }

    this.setState({ languages });
  }

  deleteLanguage = async (language_id) => {
    this.setState({confirmDialogProps: null})
    if( !await LanguageService.deleteLanguage(language_id)){
      return notify(LanguageService.error || "Unknown error");
    }

    // fetch languages from server 
    this.fetchData(true);

  }


  render() {
    const { languages, allowedLanguages, showLanguageForm, languageMenu, confirmDialogProps } = this.state;
    const {user} = this.props;

    const isAdmin = user.credentials.includes("admin");
    console.debug("language", languages);
    if (!languages) return <CircularProgress />;

    return (
      <div className="languages">
        <Grid container spacing={3}>
          {languages &&
            languages.map((l) => (
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <Card className="language-card">
                  <CardActionArea
                    component={Link}
                    to={`/translations/${l.iso639_1}`}
                  >
                    {/* <CardMedia></CardMedia> */}
                    <CardContent className="flex flex-row items-center content-space-between"                      
                    >{l.local_name}
                    { isAdmin &&  
                      <IconButton onClick={(ev) => {
                        ev.preventDefault();
                        this.setState({languageMenu: l})
                      }}
                      ref={r => this.buttonRefs[l.iso639_1] = r }
                      ><MoreVert /></IconButton>                      

                    }
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          {Array.isArray(languages) && !isAdmin && languages.length === 0 && (
            <div>
              You have no assigned translation languages. Please, contact
              administrators to get some languages assigned.
            </div>
          )}
          {
            
            isAdmin && <Grid item xs={12} sm={6} md={3} lg={2}>
              <ButtonGroup>
                <Button className="" variant="outlined" onClick={()=> this.setState({showLanguageForm: true, languageMenu: {iso639_1: null}})} ><Add/>Add language</Button>                
              </ButtonGroup>
              <LanguageForm 
                open={showLanguageForm} 
                onClose={() => this.setState({showLanguageForm: false, languageMenu : null })} 
                onSave={() => {
                  notify("Language is saved successfully", "success");
                  this.setState({showLanguageForm: false, languageMenu : null });
                  this.fetchData(true); // force reload list
                } }
                item={languageMenu}
                />
            </Grid> 
          }
        </Grid>
        <Menu open={Boolean(languageMenu && languageMenu?.iso639_1)} anchorEl={ languageMenu ? this.buttonRefs[languageMenu?.iso639_1] : null } onClose={()=> this.setState({languageMenu: null})}>
          <MenuItem onClick={() => this.setState({showLanguageForm: true})}><Edit/> Edit</MenuItem>
          <MenuItem onClick={() => this.setState(
            {
              confirmDialogProps: {prompt: `Delete the language '${languageMenu?.english_name}'?`, onConfirm: () => this.deleteLanguage(languageMenu?.iso639_1)},
              languageMenu: null
            })}><Delete/> Delete</MenuItem>
        </Menu>
        <ConfirmDialog 
          open={Boolean(confirmDialogProps)}
          prompt={confirmDialogProps?.prompt}
          onConfirm={confirmDialogProps?.onConfirm}
          onClose={() => this.setState({confirmDialogProps: null})}
        />
      </div>
    );
  }
}

const stateToProps = (state => ({
  user: state.auth.user
}))

export default connect (stateToProps)( TranslationsLanguageSelect);
