import React from "react";
import { List, ListItem, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

class TranslationsSectionList extends React.Component {
  render() {
    const { sections, language_id } = this.props;
    if (!Array.isArray(sections)) return "";

    return (
      <div className="section-list">        
        {sections.map((s) => (
          <List key={s.section_id}>
            <ListItem
              button              
              component={Link}
              to={`/translations/${language_id}/${s.section_id}`}
            >
              {s.section_title_translation_id}
            </ListItem>
          </List>
        ))}
      </div>
    );
  }
}

export default TranslationsSectionList;
