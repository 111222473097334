import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  ListItem,
  Menu,
  TextField,
} from "@material-ui/core";
import React from "react";
import { debounce } from "../../../../src/utils/functions";
import { distractionFreeEditor } from "../../../data/tinymce-configs";
import TinyEditor from "@bit/ses-education.ses-courses-shared-components.tinymce-editor";
import "./translation-desk.scss";
import ConfirmDialog from "../../template/ConfirmDialog";
import { Delete, More, MoreVert } from "@material-ui/icons";
import HelpButton from "../../template/help-button";

const tinymceKey = process.env.REACT_APP_TINYMCE;

const Input = (props) => <TextField {...props} />;

const Edit = ({ value, onSave, init = {}, ...other }) => {
  console.debug("Edit value", value);
  return (
    <TinyEditor
      // before={<div className='editor-before' data-ln1={translationLanguage}></div>}
      apiKey={tinymceKey}
      className="editor"
      // disabled={savingHtml}
      onSave={onSave}
      // onCancel={cancel}
      init={{ ...distractionFreeEditor, ...init }}
      value={value}
      {...other}
    />
  );
};

const InputDisplay = ({ value, originalLanguage }) => (
  <div data-ln1={originalLanguage} className="input-display">
    {value}
  </div>
);
const EditDisplay = ({
  value,
  originalLanguage,
  // bodyClass = "type-playandexplore category-play",
  bodyClass = "",
}) => {
  let html = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Content chapter display</title>
    <link rel="stylesheet" href="/css/custom-content.css" />
  </head>
  <body class="${bodyClass}">
    
  </body>
    ${value}
  </html>
  `;

  return (
    <div data-ln1={originalLanguage} className="edit-display">
      <iframe className="edit-display-iframe" srcDoc={html}></iframe>
    </div>
  );
};
const ParserDisplay = ({ value }) => <div className="parser-display"></div>;

class TranslationDesk extends React.Component {
  state = {
    currentValue: null,
    previoslySavedValue: null,
    isChanged: false,
    updating: false,
    title: null,
    description: null,
    confirmPrompt: null,
    onConfirm: null,
    editorKey: null,
    menuAnchor: null,
  };

  componentDidMount() {
    const { value: currentValue } = this.props;

    this.setState({
      currentValue,
      previoslySavedValue: currentValue,
      editorKey: Date.now(),
    });

    this.debouncedUpdate = debounce((v) => {
      // set 'updating' to false when sending data to server.
      // If response comes back and 'updating' is still false - the componentDidUpdate will update state
      // If the value had been changed and 'updating' is true, state won't be updated.
      this.setState({ updating: false });
      this.props.onUpdate(v);
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    const { value: currentValue } = this.props;
    // if not updating value at the moment - can update the state.
    if (!this.state.updating && currentValue !== prevProps.value)
      this.setState({
        currentValue,
        updating: false,
        previoslySavedValue: currentValue,
        // editorKey: Date.now(), // re-render the editor
      });
  }

  handleInput = ({ target }) => {
    const { currentValue, previoslySavedValue } = this.state;

    const { value } = target;
    // update state
    this.setState({
      currentValue: value,
      updating: true,
      isChanged: currentValue !== previoslySavedValue,
    });

    // debounced-update on server
    this.debouncedUpdate(value);
  };

  resetToOriginal = () => {
    console.debug("resetToOriginal");
    const { previoslySavedValue } = this.state;

    this.setState({
      currentValue: previoslySavedValue,
      previoslySavedValue,
      isChanged: false,
      onConfirm: null,
      confirmPrompt: null,
      confirmHeader: null,
      editorKey: Date.now(), // re-render the editor
    });
  };

  render() {
    const {
      originalValue, // English text
      value,
      onUpdate,
      onDelete,
      type,
      title,
      originalLanguage,
      translationLanguage,
      bodyClass,
      translationDirectionality = "ltr",
      ...other
    } = this.props;

    const {
      currentValue,
      isChanged,
      confirmPrompt,
      confirmHeader,
      onConfirm = console.debug,
      editorKey,
      menuAnchor,
    } = this.state;

    console.debug("currentValue", currentValue, "\nkey: ", editorKey);
    console.debug("type", type);

    if (currentValue === null) return <CircularProgress />;

    return (
      <div className="translation-desk">
        <h1 className="label">
          {title}
          <div className="buttons">
            <HelpButton
              content_id={5}
              variant={null}
              modalProps={{ header: "Content Translation Page" }}
            />
            <Button
              onClick={(e) => this.setState({ menuAnchor: e.currentTarget })}
              size="small"
            >
              <MoreVert />
            </Button>
            <Menu
              anchorEl={menuAnchor}
              open={!!menuAnchor}
              onClose={() => this.setState({ menuAnchor: null })}
            >
              <ListItem>Translation content options</ListItem>
              <ListItem>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    this.setState({
                      confirmHeader: "WARNING! Deleting content",
                      confirmPrompt:
                        "Translation content for the current chapter will be deleted permanently! <br/> You will have to translate this page from the beginning.<br/> Are you really sure you want to delete it?",
                      onConfirm: onDelete,
                      menuAnchor: null,
                    })
                  }
                >
                  <Delete />
                  Delete translation content
                </Button>
              </ListItem>
            </Menu>
          </div>
        </h1>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={6}
            className="english"
            data-ln1={originalLanguage}
          >
            <span className="translation-prompt">Original</span>
            {type === "input" && (
              <InputDisplay
                {...{ value: originalValue, bodyClass, ...other }}
              />
            )}
            {type === "html" && (
              <EditDisplay {...{ value: originalValue, bodyClass,  ...other }} />
            )}
            {type === "parser" && (
              <EditDisplay {...{ value: originalValue, bodyClass, ...other }} />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className="translation"
            data-ln1={translationLanguage}
          >
            {type === "input" && (
              <Input
                {...{
                  ...other,
                  value: currentValue,
                  onChange: this.handleInput,
                }}
              />
            )}
            {type === "html" && (
              <>
                <span className="translation-prompt">Translation</span>
                <Edit
                  {...{
                    ...other,
                    key: editorKey,
                    value: currentValue,
                    onSave: onUpdate,
                    onCancel: () =>
                      this.setState({
                        onConfirm: this.resetToOriginal.bind(this),
                        confirmPrompt: "Reset to previously saved content?",
                      }),
                    bodyClass,
                    init: {
                      body_class: bodyClass,
                      plugins: ["quickbars"],
                      quickbars_insert_toolbar: "undo redo | bold italic",
                      quickbars_selection_toolbar:
                        "bold italic underline clearformat | undo redo",

                      contextmenu:
                        "undo redo | inserttable | cell row column deletetable",
                      // set directionality   
                      directionality: translationDirectionality,
                    },
                  }}
                />
              </>
            )}
            {type === "parser" && (
              <Edit
                {...{ ...other, content: currentValue, onSave: onUpdate }}
              />
            )}
          </Grid>
        </Grid>
        <ConfirmDialog
          header={confirmHeader}
          open={!!confirmPrompt}
          prompt={confirmPrompt}
          onConfirm={onConfirm}
          onClose={() =>
            this.setState({
              confirmPrompt: null,
              onConfirm: null,
              confirmHeader: null,
            })
          }
        />
      </div>
    );
  }
}

export default TranslationDesk;
