import {
  Button,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Info, Warning } from "@material-ui/icons";
import React from "react";
import ProgramService from "../../services/programService";

class NewProgram extends React.Component {
  state = {
    title: "",
    slug: "",
    program_type: null,
    program_category: 4, // 4 = Play
  };

  componentDidMount() {
    const { program_type } = this.props;
    if (program_type) {
      this.setState({ program_type });
    }
  }

  onChangeValue = ({ target = {} }) => {
    const { name, value } = target;

    this.setState({ [name]: value }, () =>
      console.debug("changed state to ", this.state)
    );
    this.setState({ [name]: value });
  };

  save = async () => {
    const { id, onSave = console.debug } = this.props;
    const { title, slug, program_type, program_category } = this.state;
    if (id) {
      // TODO: update program
      return false;
    }

    // add new program
    const program = await ProgramService.addProgram(
      title,
      program_type,
      slug,
      program_category
    );
    if (!program) {
      alert(ProgramService.error);
      return false;
    }

    // call callback
    if (onSave && typeof onSave === "function") {
      onSave(program);
    }
  };

  render() {
    const { id, onClose = console.debug, categories = [] } = this.props;
    const { title, slug, program_type, program_category } = this.state;

    // if( categories === null ) return <Spinner />
    // if( categories === false ) return <ErrorMessage title="Data loading error" text="please, try reloading the page" />

    return (
      <form className="form-dialog">
        <DialogTitle>{id ? "Edit program" : "Add new program"}</DialogTitle>
        <div>
          <TextField
            fullWidth
            name="title"
            label="title text"
            required={true}
            value={title}
            onChange={this.onChangeValue}
          />
          <p className="notify info">
            <Info color="primary" />
            The English title of the program
          </p>
        </div>
        <div>
          <FormControl fullWidth>
            <InputLabel id="type-label">Program category</InputLabel>
            <Select
              labelId="type-label"
              name="program_category"
              required
              onChange={this.onChangeValue}
              fullWidth
              value={program_category}
            >
              {categories.map((c, ind) => (
                <MenuItem key={`categ-${ind}`} value={c.category_id}>
                  {c.category_title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <TextField
            fullWidth
            name="slug"
            label="Program index (e.g P1_Understand )."
            required={true}
            value={slug}
            onChange={this.onChangeValue}
          />
          <p className="notify warning">
            <Warning color="secondary" /> Program index CANNOT be changed
            afterwards!
          </p>
        </div>
        {/* <div>
                    <FormControl 
                    fullWidth
                    >
                        <InputLabel id="type-label">Program type</InputLabel>
                        <Select 
                        labelId="type-label"
                        name="program_type" 
                        required 
                        onChange={this.onChangeValue}
                        fullWidth
                        value={program_type}
                        >
                            <MenuItem value="playandexplore">Play & Explore</MenuItem>
                            <MenuItem value="runandcode">Run & Code</MenuItem>
                        </Select>
                    </FormControl>
                    
                </div> */}

        <div className="buttons">
          <Button variant="contained" color="primary" onClick={this.save}>
            Save
          </Button>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </form>
    );
  }
}

export default NewProgram;
