import tinymce from "tinymce/tinymce";

const defaultIconSelector = "p,div,img,ul,ol,h1,h2,h3,h4,h5,h6";

const icons = [
  { tooltip: "Align left", id: "align-left", icon: "align-left" },
  { tooltip: "Align center", id: "align-center", icon: "align-center" },
  { tooltip: "Align right", id: "align-right", icon: "align-right" },
  { tooltip: "Justify", id: "align-justify", icon: "align-justify" },
  { tooltip: "Reset alignment", id: "align-none", icon: "align-none" },
  {
    tooltip: "Shadow",
    id: "box-shadow",
    icon: "brightness",
    action: "optional",
    selector: "img",
  },
  {
    text: "Welcome",
    id: "welcome",
    selector: "p,div",
  },
];

export const addIconsButtons = icons.map((i) => `${i.id}`).join(" ");
export const addIconsFormats = icons.reduce((res, i) => {
  return {
    ...res,
    [`${i.id}`]: {
      selector: i.selector ? i.selector : defaultIconSelector,
      classes: i.id,
    },
  };
}, {});

tinymce.PluginManager.add("alignment", function (editor, url) {
  const createButton = (iconData) => {
    const { id, icon, text, action, tooltip } = iconData;

    // Add the button
    editor.ui.registry.addButton(`${id}`, {
      icon,
      tooltip,
      text,
      onAction: () => {
        const theFormat = `${id}`;

        const formatter = editor.formatter;
        const alreadyApplied = formatter.matchAll(icons.map((i) => `${i.id}`));

        // remove all already applied formats, if not optional
        if (alreadyApplied.length > 0 && action !== "optional") {
          alreadyApplied.forEach((f) => formatter.remove(f));
        }

        // if the current format was not previously applied, apply it.
        if (!alreadyApplied.includes(theFormat)) {
          formatter.apply(theFormat);
          return;
        }

        // else - remove it
        formatter.remove(theFormat);
        return;
      },
    });
  };

  // add all buttons
  icons.forEach((i) => {
    createButton(i);
  });

  // // Adds a menu item, which can then be included in any menu via the menu/menubar configuration
  // editor.ui.registry.addMenuItem('text-field', {
  //   text: 'Inline text field',
  //   onAction: function() {
  //   }
  // });

  return {
    getMetadata: function () {
      return {
        name: "Alignment classes (Bluefox)",
      };
    },
  };
});
