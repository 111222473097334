import { Button, Typography } from "@material-ui/core";
import React from "react";
import ModalDialog from "../modal-dialog";
import PropTypes from "prop-types";
// import ModalWindow from '../modal-window';

// class ConfirmDialog extends React.Component {
export default function ConfirmDialog(props) {
  // render() {
  const {
    prompt,
    onConfirm,
    onClose,
    cancelText = "Cancel",
    confirmText = "Yes",
    promptCssClass,
    ...other
  } = props;
  return (
    <ModalDialog
      formClassName="confirm-dialog stretch shrink"
      {...other}
      onClose={onClose}
      buttons={[
        <Button variant="contained" color="primary" onClick={onConfirm}>
          {confirmText}
        </Button>,
        <Button variant="contained" color="secondary" onClick={onClose}>
          {cancelText}
        </Button>,
      ]}
    >
      <Typography
        className={promptCssClass}
        dangerouslySetInnerHTML={{ __html: prompt }}
      ></Typography>
    </ModalDialog>
  );
  // }
}

ConfirmDialog.propTypes = {
  /** function to call on confirm */
  onConfirm: PropTypes.func,
  /** function to call on cancel */
  onCancel: PropTypes.func,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  prompt: PropTypes.string,
};

ConfirmDialog.defaultProps = {
  cancelText: "Cancel",
  confirmText: "Yes",
  promptCssClass: "flex column align-items-center",
};

// export default ConfirmDialog;
