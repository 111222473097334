const localStorageKey = "robocklyEditorHtml"


let localStorageState 

try {
    localStorageState = localStorage.getItem(localStorageKey) ? JSON.parse(localStorage.getItem(localStorageKey) ) : {}
} catch(e) {
    localStorageState = {}
}


const initialState = 
{
    ...{
        
    },
    // overwrite values from local state, if any
    ...localStorageState
}



export default function root( state = initialState, action ) { 

    let newState = {...state}
    
    switch( action.type ){
        
    
        default:
            //newState = state;
            
    }

    localStorage.setItem( localStorageKey, JSON.stringify(newState) )
    
    return newState;
}