import { AppBar, Button, IconButton, Toolbar } from "@material-ui/core";
import { BrowserRouter, Link } from "react-router-dom";
import "./App.scss";
import AuthComponent from "./components/auth";
import Main from "./components/Main";
import Service from "./services/auth";
import Notification from "./components/template/notifications/bind-redux";
import { ExitToApp, Person } from "@material-ui/icons";

function App() {
  return (
    <div className="App">
      {/* <ProgramEditor /> */}
      <BrowserRouter>
        <AppBar variant="elevation">
          <Toolbar>
            <Link to="/" className="logo-link">
              <img src="/bluefox-icon-transparent.png" />
              <span>Bluefox CMS</span>
            </Link>
            <div style={{ flexGrow: 1 }}></div>
            <div className="top-right flex row align-center">
              <IconButton component={Link} to="/profile">
                <Person />
              </IconButton>
              <Button size="small" onClick={Service.logout}>
                <ExitToApp />
                <span>Logout</span>
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <Main />
        <Notification />
      </BrowserRouter>
    </div>
  );
}

export default AuthComponent(App);
