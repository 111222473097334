import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  // Modal,
  // Paper,
  Select,
} from "@material-ui/core";
import NewProgram from "./NewProgram";
import { DataGrid } from "@material-ui/data-grid";
import { Edit } from "@material-ui/icons";
import Spinner from "../template/Spinner";

const columns = [
  { field: "slug", headerName: "Index", width: 200 },
  { field: "title", headerName: "Title", width: 500 },
  { field: "category_title", headerName: "Category", width: 200 },
  { field: "languages", headerName: "Languages", width: 200 },
  {
    field: "actions",
    headerName: "Actions",
    width: 200,
    disableClickEventBubbling: true,
    renderCell: (p) => {
      // console.debug("cellParams:", p);
      return (
        <>
          <Button
            variant="contained"
            size="small"
            color="primary"
            component={Link}
            to={`/programs/${p.row.program_type}/${p.row.slug}`}
          >
            <Edit fontSize="small" /> edit
          </Button>
        </>
      );
    },
  },
];

const defaultFilters = {
  category: "",
  language: "",
  program_index: "All",
};

class ProgramList extends React.Component {
  state = {
    showAddDialog: false,
    filters: { ...defaultFilters },
    filter_options: {
      categories: [],
      languages: [],
      program_index: [],
    },
  };

  componentDidMount() {
    this.buildFilters();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.buildFilters();
    }
  }

  /**
   * Prepare filter lists
   */
  buildFilters = () => {
    const { items } = this.props;

    const programs_by_index = items.reduce((acc, item) => {
      const { slug } = item;

      const index = slug ? slug.split("_")[0] : "???";

      acc[index] = acc[index] || { count: 0, index };
      acc[index].count++;

      return acc;
    }, []);

    console.debug("programs_by_index", programs_by_index);

    // prepare program indexes by converting the object into array of objects
    const program_index = Object.keys(programs_by_index).map(
      (key) => programs_by_index[key]
    );

    this.setState({
      // reset filter values
      filters: { ...defaultFilters },
      filter_options: {
        program_index,
      },
    });
  };

  onSaveProgram = (program) => {
    const { items = [], type, onUpdate = console.debug } = this.props;

    if (program) {
      this.setState({ showAddDialog: false });
      onUpdate(program);
    }
  };

  closeDialog = () => {
    this.setState({ showAddDialog: false });
  };

  updateFilterValue = (key, { target }) => {
    const { filters } = this.state;
    filters[key] = target.value;
    this.setState({ filters }, () => console.debug(this.state));
  };

  render() {
    const {
      items = [],
      type,
      onUpdate = console.debug,
      categories,
    } = this.props;
    const { showAddDialog, filters, filter_options } = this.state;

    if (!Array.isArray(items)) return <Spinner />;
    console.debug("items", items);
    const mappedItems = items
      .map((i) => ({
        ...i,
        id: i.program_id,
        index: i.slug ? i.slug.split("_")[0] : "???",
        title: i.title ? i.title.en || "--untitled--" : "--???---",
        languages: i.title ? Object.keys(i.title).join(", ") : [],
        actions: "",
      }))
      .filter((i) =>
        filters.program_index !== "All"
          ? i.index === filters.program_index
          : true
      );

    if (!type) return "";

    return (
      <>
        <div className="buttons">
          <div className="buttons-left">
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => this.setState({ showAddDialog: true })}
            >
              Add
            </Button>
          </div>
          <div className="buttons-right">
            <FormControl>
              <InputLabel id="program-filter">Program</InputLabel>
              <Select
                labelId="program-filter"
                value={filters.program_index}
                label="Program"
                onChange={(v) => this.updateFilterValue("program_index", v)}
                className="filter-select"
              >
                <MenuItem key="All" value={"All"}>
                  All
                </MenuItem>

                {filter_options.program_index.map((i) => (
                  <MenuItem
                    key={i.index}
                    value={i.index}
                    className="filter-item"
                    data-count={i.count}
                  >
                    {i.index}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <DataGrid
          rows={mappedItems}
          columns={columns}
          autoHeight
          // rowsPerPageOptions={[3, 5, 7]}
        />
        {/* <ul>
                {items.map( (item, ind) => (
                    <li key={`pr-${ind}`}><Link to={`/programs/${type}/${item.slug}`}>{item.title.en || "--Untitled program--"}</Link></li>
                ))}
            </ul> */}

        <Dialog
          fullWidth
          maxWidth="sm"
          open={showAddDialog}
          onClose={this.closeDialog}
        >
          <DialogContent>
            <NewProgram
              program_type={type}
              onSave={this.onSaveProgram}
              onClose={this.closeDialog}
              categories={categories}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default ProgramList;
