import {
  CircularProgress,
  Button,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";

import {
  Add,
  Check,
  Close,
  MoreVert,
  Print,
  RestorePage,
} from "@material-ui/icons";
import React from "react";
import BaseProgramService from "../../services/base-program-service";
import AdminTable from "../template/admin-table";
import ErrorMessage from "../template/ErrorMessage";
import "./style.scss";
import SectionProgramForm from "./section-program-form";
import { Link } from "react-router-dom";
import SectionService from "../../services/sectionService";
import { notify } from "../../redux/reduxFunctions";

class Section extends React.Component {
  state = {
    programs: null,
    error: null,
    editItem: null,
    moreMenuAnchor: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.section !== this.props.section) {
      this.fetchData();
    }
  }

  async fetchData() {
    const { section } = this.props;
    const { section_id } = section || {};
    console.debug("Section id", section_id);
    let programs = await BaseProgramService.getAll();

    programs =
      Array.isArray(programs) && section_id
        ? programs.filter((p) => p.base_program_section_id === section_id)
        : programs;

    this.setState({
      programs,
      error: programs ? null : BaseProgramService.error,
    });
  }

  /**
   * Gets section files' HTML bound together and prints it to the printer.
   * @returns
   */
  printSection = async () => {
    // create a printout element (overlay)
    const printDiv = document.createElement("iframe");
    printDiv.className = "print-section";

    const { section } = this.props;
    const { section_id } = section || {};

    // get section HTML
    const sectionHtmlData = await SectionService.getSectionHtml(section_id);
    if (!sectionHtmlData) {
      notify(SectionService.error || "Unknown error");
      return;
    }

    const printWindow = window.open(
      "",
      `Bluefox section ${section_id} content` // ,"height=400,width=600"
    );

    // append iframe to the page
    // printWindow.document.body.appendChild(printDiv);

    // get iframe's document object
    const iframeDoc = printWindow.document;

    // set iframe's document content to the section HTML
    iframeDoc.open();
    iframeDoc.write(sectionHtmlData);
    iframeDoc.close();

    // append CSS styles link to the iframe
    const cssLink = iframeDoc.createElement("link");
    cssLink.rel = "stylesheet";
    cssLink.href = "/css/custom-content.css";
    iframeDoc.head.appendChild(cssLink);

    printWindow.focus();

    // wait one second and then print the window
    setTimeout(() => {
      // print...
      printWindow.print();

      // close the window
      printWindow.close();
    }, 1000);

    // remove iframe from the page
    // document.body.removeChild(printDiv);
  };

  onProgramSave = async (item) => {
    const updated = await BaseProgramService.update(item);

    if (!updated) {
      alert(BaseProgramService.error);
      return false;
    }

    this.fetchData();
    return true;
  };

  /**
   * Sends a request to recreate files for the section in target BlueFox folder
   * @returns
   */
  rewriteSectionFiles = async () => {
    if (
      !(await SectionService.rewriteSectionFiles(
        this.props.section?.section_id
      ))
    ) {
      notify(
        "Could not rewrite section files: " +
          (BaseProgramService.error || "Unknown error")
      );
      this.setState({ moreMenuAnchor: null });
      return false;
    }

    notify("Files successfully rewritten!", "success");
    this.setState({ moreMenuAnchor: null });
    return true;
  };

  render() {
    const { section, sections } = this.props;
    const { programs, error, editItem, moreMenuAnchor } = this.state;

    const defaultItem = {
      base_program_id: null,
      base_program_title: "",
      base_program_section_id: section?.section_id,
    };

    const columns = [
      {
        field: "id",
        hide: true,
      },
      {
        field: "base_program_title",
        headerName: "Title",
        width: 400,
        renderCell: (params) => {
          return (
            <Link
              to={`/sections/${section?.section_id}/${params.row.base_program_id}`}
            >
              {params.value}
            </Link>
          );
        },
      },
      {
        field: "base_program_status",
        headerName: "Status",
        width: 200,
        renderCell: (params) => {
          switch (params.value) {
            case "disabled":
              return (
                <span className="flex align-center">
                  <Close htmlColor="red" />
                  {params.value}
                </span>
              );
              break;
            case "active":
            default:
              return (
                <span className="flex align-center">
                  <Check htmlColor="green" />
                  {params.value}
                </span>
              );
              break;
          }
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: "3",
        renderCell: (params) => {
          return (
            <div className="actions">
              <Button
                variant="contained"
                size="small"
                // color="primary"
                onClick={() => this.setState({ editItem: params.row })}
              >
                Rename
              </Button>
            </div>
          );
        },
      },
    ];

    const buttons = [
      <Button
        variant="contained"
        color="primary"
        onClick={() => this.setState({ editItem: { ...defaultItem } })}
      >
        <Add /> Add
      </Button>,
    ];

    console.debug("Section", section);

    if (section === null || programs === null) return <CircularProgress />;
    if (section === false || programs === false)
      return (
        <ErrorMessage header={"Could not load section data!"} error={error} />
      );

    const rows = programs.map((p) => ({ ...p, id: p.base_program_id }));
    console.debug("Section programs", programs);
    console.debug("Section Rows", rows);

    return (
      <div className="section">
        <Typography variant="h1">
          {section.section_title_translation_id}

          <div className="">
            <Button
              // variant="contained"
              // color="primary"
              onClick={this.printSection}
            >
              <Print /> Print
            </Button>

            <Button
              variant="text"
              onClick={(e) =>
                this.setState({ moreMenuAnchor: e.currentTarget })
              }
            >
              <MoreVert />
            </Button>
          </div>
          <Menu
            open={!!moreMenuAnchor}
            onClose={() => this.setState({ moreMenuAnchor: null })}
            anchorEl={moreMenuAnchor}
          >
            <MenuItem onClick={this.rewriteSectionFiles}>
              <RestorePage /> Rewrite section files
            </MenuItem>
          </Menu>
        </Typography>
        <AdminTable autoHeight {...{ columns, rows, buttons }} />

        <SectionProgramForm
          {...{ section, sections, item: editItem }}
          open={Boolean(editItem)}
          onClose={() => this.setState({ editItem: null })}
          onSave={this.onProgramSave}
        />
      </div>
    );
  }
}

export default Section;
