import React from "react";
import { Help } from "@material-ui/icons";
import "./style.scss";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import ContentService from "../../../services/contentService";
import ModalDialog from "../modal-dialog";

const defaultButton = {
  variant: null,
  color: "primary",
  size: "small",
  startIcon: <Help color="primary" />,
};

const defaultIconButton = {
  variant: null,
  color: "primary",
  size: "small",
};

class HelpButton extends React.Component {
  state = {
    content: null,
    showPopup: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // if content id has changed - refetch data
    if (prevProps.content_id !== this.props.content_id) {
      this.fetchData();
    }
  }

  fetchData = async () => {
    try {
      const content = await ContentService.getContent(this.props.content_id);
      this.setState({ content });
    } catch (e) {
      this.setState({
        content: {
          title: "Error",
          data: e.message || "Unknown error while fetching the content",
        },
      });
    }
  };

  render() {
    let {
      content_id,
      buttonText,
      Icon = <Help color="primary" />,
      // pass props to ModalWindow via modalProps object
      modalProps,
      ...other
    } = this.props;
    const { content, showPopup } = this.state;

    // default modal props (has to be defined here to use the context)
    const defaultModalProps = {
      buttons: (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.setState({ showPopup: false })}
          >
            OK
          </Button>
        </>
      ),
    };

    // override the default modal props with passed props
    modalProps = modalProps || {};
    modalProps = { ...defaultModalProps, ...modalProps };

    // select whether to show button or icon button
    const ButtonComponent = buttonText ? Button : IconButton;
    const defaultButtonProps = buttonText ? defaultButton : defaultIconButton;
    const buttonProps = { ...defaultButtonProps, ...other };

    return (
      <>
        <ButtonComponent
          onClick={() => this.setState({ showPopup: true })}
          {...buttonProps}
        >
          {buttonText ? buttonText : Icon}
        </ButtonComponent>

        <ModalDialog
          open={showPopup}
          onClose={() => this.setState({ showPopup: false })}
          {...modalProps}
        >
          {(content && (
            <div dangerouslySetInnerHTML={{ __html: content.data }}></div>
          )) || <CircularProgress />}
        </ModalDialog>
      </>
    );
  }
}

export default HelpButton;
