import A from "../redux/actionTypes";
import baseClientService from "./baseClientService";

class UserService extends baseClientService {
  static async login(email, password) {
    try {
      const result = await this.POST("/auth/admin/login", { email, password });

      if (result && result.user && result.token) {
        return result;
      } else {
        console.debug("Wrong formatted response received: ", result);
        this.Error("Something went wrong");
        return false;
      }
    } catch (e) {
      console.error("Login error: ", e.message);
      return this.Error(e.message || e || "unknown error");
    }
  }

  static async logout() {
    const me = this;
    UserService.dispatch("LOGOUT");
    // try{

    //     const result = await this.POST("/auth/admin/logout")

    //     if( result ){
    //         me.dispatch("LOGOUT");
    //     } else {
    //         me.Error( "Error logging out" )
    //         return false;
    //     }
    // } catch(e){
    //     console.error("Login error: ", e.message );
    //     me.Error("Error accessing server")
    //     return false;
    // }
  }

  static async renew() {
    try {
      const result = await this.POST("/auth/renew");
      console.debug("renewed token data:", result);
      if (result && result.token) {
        const { token } = result;
        console.debug("Renewed token: ", token);
        this.dispatch(A.RENEW_TOKEN, token);
        return true;
      }
      console.error("Wrong data received: ", result);
    } catch (e) {
      console.error("Error renewing token: ", e.message);
      return false;
    }
  }

  static async changePassword(password) {
    try {
      return await this.POST("/auth/change-password", { password });
    } catch (e) {
      throw new Error(e || "Unknown error");
    }
  }
}

baseClientService.extend(UserService);

export default UserService;
