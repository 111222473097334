import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import menu from "../../data/menu.json"; // import menu data
import store from "../../redux/store";
import { arraysIntersect } from "../../utils/functions";

const MenuBlock = ({ items }) => {
  // get user credentials from redux
  const userCredentials = store.getState().auth.user?.credentials || [];

  return (
    <ul className="menu-block">
      {Array.isArray(items) &&
        items.map(({ title, to, _children, credentials = [] }) => {
          // only show element if user has access to it (no credentials are set for the element or user has at least one of the credentials)
          if (
            !Array.isArray(credentials) ||
            credentials.length === 0 ||
            arraysIntersect(userCredentials, credentials)
          ) {
            return (
              <li key={`link-${to}`}>
                <Link to={to}>{title}</Link>
                {_children && <MenuBlock items={_children} />}
              </li>
            );
          }
        })}
    </ul>
  );
};

class Dasboard extends React.Component {
  render() {
    return (
      <div className="dashboard">
        <MenuBlock items={menu} />
      </div>
    );
  }
}

export default Dasboard;
