import React from "react";
// import { Button, List, ListItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import AdminTable from "../template/admin-table";
// import BaseProgramService from "../../services/base-program-service";

class TranslationsProgram extends React.Component {
  componentDidMount() {}

  async fetchData() {}

  render() {
    const { program, section, language_id } = this.props;
    if (!program) return "";

    const rows = section.categories
      .map((c) => ({ ...c, id: c.category_id }))
      .sort((a, b) => a.ordering - b.ordering);
    const buttons = [];

    const columns = [
      {
        field: "id",
        hide: true,
      },
      {
        field: "category_title",
        headerName: "Program chapter",
        width: 400,
        renderCell: (params) => {
          return (
            <Link
              to={`/translations/${language_id}/${section?.section_id}/${program.base_program_id}/${params.row.category_slug}`}
            >
              {params.value}
            </Link>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: "3",
        renderCell: (params) => {
          return (
            <div className="actions">
              {/* <Button
                variant="contained"
                size="small"
                // color="primary"
                onClick={() => this.setState({ editItem: params.row })}
              >
                Rename
              </Button> */}
            </div>
          );
        },
      },
    ];

    return (
      <div className="section">
        <AdminTable
          autoHeight
          {...{ columns, rows, buttons, displaySearch: false }}
        />
      </div>
    );
  }
}

export default TranslationsProgram;
