import tinymce from "tinymce/tinymce";

/**
 * Adds special blocks to the editor.
 */

const defaultBaseSelector = "p,div";

const items = [
  // { text: "Align left", id: "align-left", icon: "align-left" },
  // { text: "Align center", id: "align-center", icon: "align-center" },
  // { text: "Align right", id: "align-right", icon: "align-right" },
  // { text: "Justify", id: "align-justify", icon: "align-justify" },
  // { text: "Reset alignment", id: "align-none", icon: "align-none" },
  {
    text: "Task container",
    id: "container-1",
    onAction: (editor, itemData) => {
      console.debug("editor:", editor);
      const { id } = itemData;
      const theFormat = `${id}`;

      const formatter = editor.formatter;
      const alreadyApplied = formatter.matchAll(items.map((i) => `${i.id}`));

      // remove all already applied formats, if not optional
      if (alreadyApplied.length > 0) {
        alreadyApplied.forEach((f) => formatter.remove(f));

        // convert to paragraph
        editor.execCommand("FormatBlock", false, "p");
      }

      // if the current format was not previously applied, apply it.
      if (!alreadyApplied.includes(theFormat)) {
        // convert element to div
        editor.execCommand("FormatBlock", false, "div");

        formatter.apply(theFormat);

        // insert paragraf within div
        editor.insertContent("<h2>Task 00</h2><p>Content goes here</p>");

        return;
      }

      // else - remove it
      formatter.remove(theFormat);
      return;
    },
  },

  {
    text: "Commands",
    id: "commands",
    selector: "p,div",
    onAction: (editor, itemData) => {
      // const { id } = itemData;
      // const theFormat = `${id}`;

      // const formatter = editor.formatter;
      editor.insertContent(`<table class="commands">
      <tbody>
        <tr>
          <td>Command</td><td>Description</td>
        </tr>
        <tr>
          <td>Command</td><td>Description</td>
        </tr>
      </tbody>
      </table>`);

      return;
    },
  },

  {
    text: "Commands 2",
    id: "commands2",
    selector: "p,div",
    onAction: (editor, itemData) => {
      // const { id } = itemData;
      // const theFormat = `${id}`;

      // const formatter = editor.formatter;
      editor.insertContent(`<table class="commands type2">
      <tbody>
        <tr>
          <td><p>Command 1</p></td>
        </tr>
        <tr>
          <td><p>Command 2</p></td>
        </tr>
      </tbody>
      </table>`);
      return;
    },
  },
  {
    /**
     * Blue-colored unordered list
     */
    text: "Blue list",
    selector: "ul",
    id: "blue-list",
    onAction: (editor, itemData) => {
      const { id } = itemData;
      const theFormat = `${id}`;

      console.debug("theFormat:", theFormat);
      console.debug("editor:", editor);

      const formatter = editor.formatter;
      const alreadyApplied = formatter.matchAll(items.map((i) => `${i.id}`));
      console.debug("alreadyApplied:", alreadyApplied);

      // remove all already applied formats, if not optional
      if (alreadyApplied.length > 0) {
        alreadyApplied.forEach((f) => formatter.remove(f));
      }

      // if the current format was not previously applied, apply it.
      if (
        !alreadyApplied.includes(theFormat) &&
        formatter.canApply(theFormat)
      ) {
        formatter.apply(theFormat);
        return;
      } else {
        console.debug("cannot apply");
      }

      // else - remove it
      if (alreadyApplied.includes(theFormat)) {
        console.debug("already aaplied! removing...");
        formatter.remove(theFormat);
      }
      return;
    },
  },
  {
    /**
     * diagram image (100% width image without limit to its height)
     */
    text: "Diagram",
    selector: "img",
    id: "diagram-image",
    onAction: (editor, itemData) => {
      const { id } = itemData;
      const theFormat = `${id}`;

      const formatter = editor.formatter;
      const alreadyApplied = formatter.matchAll(items.map((i) => `${i.id}`));

      // if the current format was not previously applied, apply it.
      if (
        !alreadyApplied.includes(theFormat) &&
        formatter.canApply(theFormat)
      ) {
        formatter.apply(theFormat);
        return;
      } else {
        console.debug("cannot apply");
      }

      // else - remove it
      if (alreadyApplied.includes(theFormat)) {
        // console.debug("already aaplied! removing...");
        formatter.remove(theFormat);
      }
      return;
    },
  },
  {
    text: "Yellow row",
    id: "yellow-row",
    // hide: true, // don't add to buttons
    isMenuItem: true, // add as menu item
    selector: "table.commands.type2 tr", // attemp to only work within specific class and element
    onAction: (editor, itemData) => {
      const { id } = itemData;
      const theFormat = `${id}`;

      const formatter = editor.formatter;
      const alreadyApplied = formatter.matchAll(items.map((i) => `${i.id}`));
      console.debug("yellow-row alreadyApplied:", alreadyApplied);

      // if the format had been applied - remove it
      if (alreadyApplied.includes(theFormat)) {
        console.debug("yellow-row already aaplied! removing...");
        formatter.remove(theFormat);
        return;
      }

      // if the current format was not previously applied, apply it.
      if (formatter.canApply(theFormat)) {
        formatter.apply(theFormat);
        console.debug("yellow-row applied");
        return;
      }

      console.debug("yellow-row: cannot apply");
      return;
    },
  },
];

export const addButtons = "run-and-code-button";
export const addFormats = items.reduce((res, i) => {
  return {
    ...res,
    [`${i.id}`]: {
      selector: i.selector ? i.selector : defaultBaseSelector,
      classes: i.id,
    },
  };
}, {});

tinymce.PluginManager.add("run-and-code-blocks", function (editor, url) {
  const createButton = (itemData) => {
    const { id, icon, text, onAction } = itemData;

    // Add the button
    editor.ui.registry.addButton(`${id}`, {
      icon,
      text,
      tooltip: text,
      onAction: () => onAction(editor, itemData),
    });
  };

  editor.ui.registry.addGroupToolbarButton("run-and-code-button", {
    text: "Run&Code",
    items: items
      .filter((i) => !i.hide)
      .map((i) => `${i.id}`)
      .join(" "),
    tooltip: "Run & Code options",
  });

  const createMenuItem = (item) => {
    console.debug("adding menu item", item.id);
    editor.ui.registry.addMenuItem(item.id, {
      text: item.text,
      onAction: () => item.onAction(editor, item),
    });
  };

  // add all buttons
  items.forEach((i) => {
    createButton(i);
  });

  // add menu items
  items.filter((i) => i.isMenuItem).forEach((i) => createMenuItem(i));

  // // Adds a menu item, which can then be included in any menu via the menu/menubar configuration

  // editor.ui.registry.addMenuItem("yellow-row", {
  //   text: "Yellow row",
  //   onAction: function () {
  //     console.debug("clicked!");
  //   },
  // });

  console.debug("Adding custom toolbar");

  editor.ui.registry.addContextMenu("run-and-code-blocks", {
    update: (element) => {
      console.debug("checking commands-table menu for ", element);
      return "yellow-row";
    }, // (editor.dom.is(element, "tr") ? "yellow-row" : ""),
  });

  return {
    getMetadata: function () {
      return {
        name: "Run & Code Blocks (Bluefox)",
      };
    },
  };
});
