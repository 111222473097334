// import { CircularProgress } from "@material-ui/core";
import { Home } from "@material-ui/icons";
import React from "react";
import TranslationService from "../../services/translationService";
import AutoBreadcrumbs from "../template/AutoBreadcrumbs";
import "./style.scss";
import TranslationsLanguageSelect from "./translations-language-select";
import TranslationsProgram from "./translations-program";
import TranslationsProgramCategory from "./translations-program-category";
import TranslationsSection from "./translations-section";
import TranslationsSectionList from "./translations-section-list";

class Translations extends React.Component {
  state = {
    sections: null,
    error: null,
    section: null,
    program: null,
    category: null,
    language: null
  };

  async componentDidMount() {
    this.fetchData();
    this.selectLanguage();
  }

  async componentDidUpdate(prevProps) {
    const { section_id, program_id, category_slug, language_id } =
      this.props.match.params;
    const { params } = prevProps.match;

    let { section, program, category } = this.state;

    let changed = false;

    if (params.language_id !== language_id) {
      // reset everything
      changed = true;
      section = null;
      program = null;
      category = null;
      // not a good coding, but whatever
      this.selectLanguage();
    }

    console.debug(
      "componentDidUpdate",
      this.props.match.params,
      prevProps.match.params
    );

    

    if (params.section_id !== section_id) {
      const { sections } = this.state;
      // reset section
      section = null;

      // update section value
      section = this.selectSection(sections, section_id);
      console.debug("selected section:", section);
      // this.setState({ section });
      changed = true;
    }

    if (
      params.program_id !== program_id ||
      params.category_slug !== category_slug
    ) {
      // reset program and category values
      // if we don't do this, the program and category may stay in state
      program = null;
      category = null;

      // fetch from DB
      program = await this.selectBaseProgram(program_id);
      if (program && category_slug) {
        // select category
        category = this.selectCategory(program, category_slug);
      }
      changed = true;
    }

    // After every update, update state
    if (changed)
      this.setState({
        section,
        program,
        category,
      });
  }

  /**
   * Fetch sections data from server
   */
  async fetchData(forceReload = false) {
    let error = 0,
      section = null,
      program = null,
      category = null;

    const sections = await TranslationService.getSections(forceReload);
    if (!sections) error = TranslationService.error;

    const { section_id, program_id, category_slug } = this.props.match.params;

    section = this.selectSection(sections, section_id);
    program = await this.selectBaseProgram(program_id, forceReload);
    console.debug("program, category_id", program, category_slug);
    if (program && category_slug) {
      console.debug("program is found, selecting category", category_slug);
      category = this.selectCategory(program, category_slug);
    }

    this.setState({ sections, error, section, program, category });
  }

  async selectLanguage(){
    const {language_id} =  this.props.match.params;
    const language = language_id ? await TranslationService.getLanguage(language_id) :  null;
    this.setState({language});
  }

  async selectBaseProgram(program_id, forceReload = false) {
    if (!program_id) return false;
    const programs = await TranslationService.getPrograms(forceReload);
    if (!Array.isArray(programs)) return false;
    program_id = parseInt(program_id);
    console.debug("programs", programs);
    console.debug(
      "found program",
      programs.find((p) => p.base_program_id === program_id)
    );
    return programs.find((p) => p.base_program_id === program_id);
  }

  selectSection(sections, section_id) {
    if (section_id && Array.isArray(sections)) {
      return sections.find((s) => s.section_id === section_id);
    }
    return false;
  }

  /**
   * Select content category from program by category id
   * @param {*} program
   * @param {*} category_slug
   * @returns
   */
  selectCategory(program, category_slug) {
    console.debug("selecting category", category_slug, program);
    if (category_slug && Array.isArray(program.content)) {
      return program.content.find((c) => c.category_slug === category_slug);
    }
    return false;
  }

  render() {
    const { sections, error, section, program, category, language } = this.state;
    const { category_id, language_id } = this.props.match.params;

    let breadcrumbs = [
      {
        to: "/",
        icon: <Home />,
      },
      {
        to: "/translations",
        text: "Translations",
      },
    ];

    if (language_id) {
      breadcrumbs.push({
        to: `/translations/${language_id}`,
        text: language_id,
      });
    }

    // if (category_id && !category && !error) return <CircularProgress />;

    console.debug("Translations", section, program, category);

    const isLanguageSelect = !language_id;
    const isList = language_id && !section && sections && language;
    const isSection = section && !category && !program;
    const isProgram = section && program && !category;
    const isCategory = section && program && category;
    console.debug(
      "is list, section, program, category:",
      isList,
      isSection,
      isProgram,
      isCategory
    );
    if (section) {
      breadcrumbs.push({
        to: `/translations/${language_id}/${section.section_id}`,
        text: section.section_title_translation_id,
      });
    }
    if (program) {
      breadcrumbs.push({
        to: `/translations/${language_id}/${section.section_id}/${program.base_program_id}`,
        text: program.base_program_title,
      });
    }
    if (category) {
      breadcrumbs.push({
        to: `/translations/${language_id}/${section.section_id}/${category.category_id}`,
        text: category.category_title,
      });
    }

    return (
      <div className="sections translations">
        <AutoBreadcrumbs items={breadcrumbs} />
        <h1>
          {isLanguageSelect && "Translations"}
          {isList && `Translations to ${language.english_name} :: Sections`}
          {isSection && `${section.section_title_translation_id}`}
          {isProgram && `Program '${program.base_program_title}'`}
          {/* {isCategory &&
            `Program '${program.base_program_title}', chapter '${category.category_title}'`} */}
        </h1>

        {isLanguageSelect && <TranslationsLanguageSelect />}
        {isList && (
          <TranslationsSectionList
            sections={sections}
            language_id={language_id}
          />
        )}
        {isSection && (
          <TranslationsSection section={section} language_id={language_id} />
        )}
        {isProgram && (
          <TranslationsProgram {...{ program, section, language_id }} />
        )}
        {isCategory && (
          <>
            <TranslationsProgramCategory
              {...{
                program,
                section,
                category,
                language_id,
                title: `Program '${program.base_program_title}', chapter '${category.category_title}'`,
              }}
            />
          </>
        )}
      </div>
    );
  }
}

export default Translations;
