import A from "./actionTypes";
import AuthActions from "./auth-actions";

const localStorageKey = "robocklyEditorAuth";

let localStorageState;

try {
  localStorageState = localStorage.getItem(localStorageKey)
    ? JSON.parse(localStorage.getItem(localStorageKey))
    : {};
} catch (e) {
  localStorageState = {};
}

const initialState = {
  ...{
    user: null,
    authorization: null,

    // These are used to keep track of user interaction with interface and token expiration
    lastUsed: Date.now(),
    tokenTimestamp: Date.now(),
  },
  // overwrite values from local state, if any
  ...localStorageState,
};

export default function root(state = initialState, action) {
  let newState = { ...state };

  const { type, payload } = action;

  //   let user, token, authorization;

  switch (type) {
    case A.LOGIN:
      {
        const now = Date.now();
        const { user, token } = payload;
        const authorization = `Bearer ${token}`;
        const tokenTimestamp = now;
        newState = { ...newState, user, authorization, tokenTimestamp };
        AuthActions.onUpdateToken();
      }
      break;

    case A.LOGOUT:
      newState = {
        ...newState,
        user: null,
        authorization: null,
        lastUsed: null,
        tokenTimestamp: null,
      };
      AuthActions.clearTokenUpdateTimer();
      break;

    case A.RENEW_TOKEN:
      {
        const now = Date.now();
        newState = {
          ...newState,
          authorization: `Bearer ${payload}`,
          tokenTimestamp: now,
        };
        AuthActions.onUpdateToken();
      }
      break;

    case A.SET_USAGE_TIMESTAMP:
      {
        try {
          const lastUsed = Date.now();
          newState = { ...newState, lastUsed };
          console.debug("onUpdateToken updating lastUsed to", lastUsed);
        } catch (e) {
          console.error(e.message);
        }
      }
      break;

    default:
    //newState = state;
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newState));

  return newState;
}
