import React from 'react'
import ModalDialog from '../template/modal-dialog';
import { Button, FormControl, FormLabel,  Switch,  TextField } from '@material-ui/core';
import LanguageService from '../../services/LanguageService';
import { notify } from '../../redux/reduxFunctions';
import SelectSearch from '../template/select-search';
import uniqid from 'uniqid';


const defaultItem = {
    active: 1,
    direction: "ltr",
    english_name: "",
    english_short: "",
    iso639_1: "",
    local_name: "",
    local_short: "",
}

class LanguageForm extends React.Component{

    state = {
        item: {...defaultItem},
        languages: null,
        formId: null
    }


    formRef = null;

    async componentDidMount(){
        const {item = defaultItem} = this.props;
        

        const languages = await LanguageService.getIsoList();
        if( !languages ){
            return notify(LanguageService.error || "Unknown error");
        }

        const formId = uniqid("language-form-");
        this.setState({item, languages, formId})
    }
    componentDidUpdate(prevProps){        
        const {item = defaultItem} = this.props;
        if( prevProps?.item?.iso639_1 !== item?.iso639_1 ){
            this.setState({item});
        }
    }

    onSelectLanguage = (language,  name) => {
        console.debug(language, "\n", name)
        if( typeof language !== "object" || !language.iso639_1 ) {
            // if empty or erroneous - reset data
            return this.setState({item: {...defaultItem}});
        }
        const item = {
            ...defaultItem,            
            iso639_1: language.iso639_1,
            // if iso639_2 is an array - choose the first element as 3-ltters code
            english_short: Array.isArray(language.iso639_2) ? language.iso639_2[0] :  "",            
            english_name: language.english_name,
            local_name: "",            
        }
        this.setState({item});
    }

    onSubmit = async () => {
        console.debug("onSubmit is called");
        const {item} = this.state;
        const {item: itemProps, onSave = console.debug }  = this.props;
        
        console.debug("data:", item);

        const languageSelected = Boolean((itemProps || {}).iso639_1);
        
        let result;
        if( languageSelected ){
            result = await LanguageService.updateLanguage(item.iso639_1, item);
        } else {
            result = await LanguageService.addLanguage(item);
        }

        if( !result ){
            return notify( LanguageService.error || "Unknown error");
        }

        // call onSave callback
        onSave()
        return false;
    }

    /**
     * Update field in state.item based on name and value
     * @param {Event} event 
     */
    onInput = ({target}) => {
        let {item = {...defaultItem} } = this.state;
        item = {...item, [target.name ] : target.value }
        this.setState({item})
    }

    setActive = ({target}) => {
        console.debug(target.checked);
        
        let {item = {...defaultItem} } = this.state;
        item = {...item, "active": target.checked ? 1 : 0 }
        this.setState({item})
    }

    render(){
        const {item = {} , languages, formId} = this.state;
        const { item: itemProps, ...other } = this.props;

        console.debug("form props item", itemProps)
        console.debug("form item", item)
        
        // whether we were passed an already existing language object
        const languageSelected = Boolean((itemProps || {}).iso639_1);

        // console.debug("open:", other.open);
        if( !formId || !item) return <></>


        return(
        <ModalDialog
            {...other}
            header={ languageSelected ? "Edit language preferences" :  "Add new language"}
        >
            {/* <form onSubmit={this.onSubmit} className="flex column">  */}
            <form onSubmit={(e)=> console.debug("!!!!!")} className="flex column" id={formId} 
                // store form ref for the submit button to work.... 
                ref={(r => this.formRef = r)}> 
                { !languageSelected && languages && (
                    <SelectSearch 
                    label="Language"
                    items={languages} 
                    titleField="english_name" 
                    valueField="iso639_1" 
                    onSelect={this.onSelectLanguage} 
                    disabled={languageSelected}
                    />
                ) }
                
                
                <FormControl className="m-top-20-forced">
                    <FormLabel>3-letters ISO-639 code</FormLabel>                    
                    <TextField disabled name='english_short' value={item.english_short} onInput={this.onInput}/>
                </FormControl>
                <FormControl className="m-top-20-forced">
                    <FormLabel>3-letters short name in local language (e.g."עבר")</FormLabel>
                    <TextField name='local_short' value={item.local_short || ""} onInput={this.onInput}/>
                </FormControl>
                <FormControl className="m-top-20-forced">
                    <FormLabel>Name in local language (e.g. עברית)</FormLabel>                                        
                    <TextField name='local_name' value={item.local_name || ""} onInput={this.onInput}/>
                </FormControl>                
                <FormControl className="m-top-20-forced m-bottom-20-forced">
                    <FormLabel>Language is active</FormLabel>                                        
                    <Switch checked={item.active} onChange={this.setActive} />
                </FormControl>
                <div className="flex items-center content-space-between">
                    <Button variant="contained" color="primary" disabled={!item || !item.iso639_1} type="button" form={formId} onClick={this.onSubmit}>Save</Button>
                    <Button variant="contained" color="secondary" type="button" onClick={other.onClose}>Cancel</Button>
                </div>
            </form>

        </ModalDialog>)
    }
}

export default LanguageForm