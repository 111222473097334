/**
 * Returns true if arr1 contains at least one of elements of arr2
 * @param {*} arr1
 * @param {*} arr2
 * @returns
 */
const arraysIntersect = (arr1, arr2) =>
  Array.isArray(arr1) &&
  Array.isArray(arr2) &&
  arr1.some((el) => arr2.includes(el));

/**
 * Debounce function
 * @param {*} func
 * @param {*} timeout
 * @returns
 */
function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

/**
 * Returns a nested value from an object using an array of strings denominating path to the value.
 * @usage getNestedObject( { a: { b: 1, c: 2, d: { e: 4 } } }, ["a", "d", "e"] ) // returns 4
 * @param {*} objectToNavigate
 * @param {*} pathArray
 * @returns
 */
function getNestedObject(objectToNavigate, pathArray) {
  if (!Array.isArray(pathArray))
    throw new Error("getNestedObject: path is not an array");

  if (!objectToNavigate || typeof objectToNavigate !== "object")
    throw new Error(
      "getNestedObject: object expected, got",
      typeof objectToNavigate
    );

  return pathArray.reduce(
    (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
    objectToNavigate
  );
}

export { arraysIntersect, debounce, getNestedObject };
