import React from "react";
import { connect } from "react-redux";
import { hideMessage, clearAllMessages } from "./notificationActions";
import Notifications from "./index.jsx";

class Notification extends React.Component {
  render() {
    const { messages, onCloseMessage, onCloseAll } = this.props;
    return (
      <Notifications
        {...{
          messages,
          onCloseMessage,
          onCloseAll,
        }}
        config={{
          topToBottom: true,
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.notifications.messages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseMessage: (ind) => hideMessage(ind)(dispatch),
    onCloseAll: () => clearAllMessages()(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
