import React from "react";
import "./style.scss";
import { NavLink, Link } from "react-router-dom";
import ProgramList from "./ProgramList";
import ProgramService from "../../services/programService";
import {
  Breadcrumbs,
  Button,
  ButtonGroup,
  Typography,
} from "@material-ui/core";
import Program from "../Program";
import Spinner from "../template/Spinner";
import ErrorMessage from "../template/ErrorMessage";
import Page404 from "../Page404";
import { Home } from "@material-ui/icons";

class Programs extends React.Component {
  state = {
    programs: null,
    error: null,
    types: null,
  };

  componentDidMount() {
    this.fetchData();
    // this.fetchTypes();
  }

  // async fetchTypes(){
  //     const types = await ProgramService.getTypes();

  //     this.setState({types})
  // }

  async fetchPrograms(forceReload = false) {
    const programs = await ProgramService.getAllPrograms(forceReload);
    this.setState({ programs });
  }

  async fetchData() {
    this.fetchPrograms();
    const categories = await ProgramService.getCategories();
    const types = await ProgramService.getTypes();

    this.setState({ categories, types });

    // console.debug( "programs:", programs );
    // if( programs ){
    //     console.debug("Programs:", programs );

    //     this.setState({programs, error: null})
    //     return true;
    // }
    // const error = ProgramService.error;
    // this.setState({programs: false, error })
  }

  onListUpdate = () => {
    console.debug("onListUpdate: forcing reload");
    this.fetchPrograms(true); // force reload
  };

  render() {
    const { type, id, lang = "en" } = this.props.match.params;

    const { programs, error, types, categories } = this.state;

    if (programs === null || types === null || categories === null)
      return <Spinner />;
    if (programs === false || categories === false)
      return (
        <ErrorMessage
          title="Data loading error"
          text="please, try reloading the page"
        />
      );

    const items = programs.filter((p) => p.program_type === type);

    const program = id ? programs.find((p) => p.slug === id) : null;

    if (program === undefined) return <Page404 text="Program was not found" />;

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/">
            <Home />
          </Link>
          <Link color="inherit" to="/programs">
            Programs
          </Link>
          {type && (
            <Link color="inherit" to={`/programs/${type}`}>
              {types[type].title || "Unknown type"}
            </Link>
          )}

          {program && (
            <Link color="inherit" to={`/programs/${type}/${id}`}>
              {program.title[lang || "en"] || "-- no translation yet --"}
            </Link>
          )}

          {/* {lang && 
                    <Link color="inherit" to={`/programs/${type}/${id}/${lang}`} >
                        {lang}
                    </Link>
                }
                 */}
        </Breadcrumbs>
        {id && (
          <Program {...{ id, lang, program }} onUpdate={this.onListUpdate} />
        )}
        {!id && (
          <div className="programs">
            <h1>Programs</h1>
            <ButtonGroup className="program-types-nav">
              <Button component={NavLink} to="/programs/playandexplore">
                Play & Explore programs
              </Button>
              <Button component={NavLink} to="/programs/runandcode">
                Run & Code programs
              </Button>
            </ButtonGroup>

            <ProgramList
              onUpdate={this.onListUpdate}
              {...{ items, type, categories }}
            />
          </div>
        )}
      </>
    );
  }
}

export default Programs;
