import { Button, MenuItem, Select, TextField } from "@material-ui/core";
import React from "react";
import ModalWindow from "../template/modal-dialog";
import "./style.scss";

const defaultUser = {
  id: null,
  name: "",
  email: "",
  active: 1,
};

class UserForm extends React.Component {
  state = {
    user: { ...defaultUser },
    hasChanges: false,
    error: null,
  };

  componentDidMount() {
    let { user = {} } = this.props;

    user = { ...defaultUser, ...user };

    this.setState({ user });
  }

  componentDidUpdate(prevProps) {
    const { user = defaultUser } = this.props;
    if (user !== prevProps.user) {
      this.setState({ user });
    }
  }

  /**
   * Update field value
   * @param {*} ev
   */
  onValueChange = (ev) => {
    console.debug("onValueChange", ev);
    const { name, value } = ev.target;
    const originalUser = this.props.user || {};

    // prepare user object for update
    let { user } = this.state;
    // update value
    user = { ...user, [name]: value };

    // compare to original user object to determine if there are changes
    const hasChanges = JSON.stringify(originalUser) !== JSON.stringify(user);
    console.debug(
      "hasChanges",
      hasChanges,
      JSON.stringify(originalUser),
      JSON.stringify(user)
    );

    this.setState({ user, hasChanges });
  };

  render() {
    const { onSubmit = console.debug, ...other } = this.props;
    const { user, hasChanges, error } = this.state;
    const { id, name, email, active } = user || {};

    return (
      <ModalWindow
        header={id ? "Edit user" : "Create user"}
        {...other}
        formClassName="shrink"
      >
        <div className="user-form">
          <TextField
            name="name"
            label="User Full Name"
            type="text"
            value={name}
            onChange={this.onValueChange}
          />
          <TextField
            name="email"
            label="Email"
            type="email"
            value={email}
            onChange={this.onValueChange}
          />
          <Select
            name="active"
            value={active}
            onChange={this.onValueChange}
            label="Status"
          >
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select>
          <div className="error-message">{error}</div>
          <div className="flex row content-center">
            <Button
              disabled={!hasChanges}
              variant="contained"
              color="primary"
              onClick={() => onSubmit(user)}
            >
              {id ? "Update" : "Create"}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.props.onClose}
            >
              Close
            </Button>
          </div>
        </div>
      </ModalWindow>
    );
  }
}

export default UserForm;
