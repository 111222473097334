import { combineReducers } from "redux";
import html from "./htmlEditorReducers";
import auth from "./authReducers";
import notifications from "../components/template/notifications/reducers";

export default combineReducers({
  html,
  auth,
  notifications,
});
