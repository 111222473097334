import React from 'react'
import "./style.scss";

class Page404 extends React.Component{

    render(){
        const {header= "Error 404: Page not found", text} = this.props;
        return(
            <>
                <h1>{header}</h1>
                { text && <div>{text}</div> }
            </>
            )
    }
}

export default Page404