import React from "react";
import routes from "../../data/routes.json";
import { BrowserRouter, Switch } from "react-router-dom";
import Route from "./Route";
import "./main.scss";
import AuthActions from "../../redux/auth-actions";

import store from "../../redux/store";
import A from "../../redux/actionTypes";

let usageDefer = Date.now();
const deferUsageDelay = 100; // ms - time to defer usage interaction by.

class Main extends React.Component {
  componentDidMount() {
    // monitor interface usage
    document
      .getElementById("root")
      .addEventListener("click", this.trackUserInteraction);

    // this is the first run (or a reloaded page)
    // so let's check if the token has to be renewed
    // const { lastUsed, user } = store.getState().auth;
    AuthActions.checkIfTokenHasToBeUpdated();
  }

  /**
   * Tracks user interaction with UI and updates the lastUsed timestamp in redux state.
   * Difference between lastUsed timestamp and current moment
   * is used to deterimne whether to renew auth token when it's about to expire.
   */
  trackUserInteraction() {
    console.debug("UI interacted with");
    // defer consecutive interactions
    clearTimeout(usageDefer);
    usageDefer = setTimeout(
      () => store.dispatch({ type: A.SET_USAGE_TIMESTAMP }),
      deferUsageDelay
    );
  }

  render() {
    return (
      <div id="main">
        <Switch>
          {routes
            ? routes.map((r) => <Route key={`route-${r.path}`} {...r} />)
            : "Error reading routing data"}
        </Switch>
      </div>
    );
  }
}

export default Main;
