import React from "react";
import { Add, Edit, Group, Home } from "@material-ui/icons";
import AutoBreadcrumbs from "../template/AutoBreadcrumbs";
import "./style.scss";
import UsersService from "../../services/usersService";
import { notify } from "../../redux/reduxFunctions";
import AdminTable from "../template/admin-table";
import User from "./user";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, CircularProgress } from "@material-ui/core";
import ErrorMessage from "../template/ErrorMessage";
import UserForm from "./user-form";

class Users extends React.Component {
  state = {
    users: null,
    confirmHeader: null,
    confirmText: null,
    confirmAction: null,
    user: null,
    editUser: null,
    error: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.user_id !== prevProps.match.params.user_id) {
      const user = this.selectUser(this.state.users);
      this.setState({ user });
    }
  }

  fetchData = async (forceReload = false) => {
    try {
      let users = await UsersService.getAll(forceReload);
      const user = this.selectUser(users);
      console.debug("user found:", user);
      this.setState({ users, user });
    } catch (e) {
      // notify(e.message || e || "Unknown error", "error");
      this.setState({ users: false, error: e.message || e || "Unknown error" });
    }
  };

  selectUser = (users) => {
    let { user_id } = this.props.match.params;
    user_id = parseInt(user_id); // cast to int to compare with id
    console.debug("selecting user", user_id);
    return users.find((user) => user.id === user_id);
  };

  updateUser = async (user) => {
    try {
      await UsersService.createOrUpdate(user);
      this.fetchData(true);
      this.setState({ editUser: null });
      notify("User updated", "success");
    } catch (e) {
      notify(e.message || e || "Unknown error", "error");
    }
  };

  render() {
    const { user_id } = this.props.match.params;
    const { users, user, error, editUser } = this.state;

    const columns = [
      {
        field: "id",
        headerName: "#",
        hidden: true,
      },
      //   {
      //     field: "section_id",
      //     headerName: "Id",
      //   },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        align: "left",
        renderCell: (params) => {
          return <Link to={`/users/${params.row.id}`}>{params.value}</Link>;
        },
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        align: "left",
      },
      {
        field: "active",
        headerName: "Is active",
        flex: 1,
        valueGetter: (params) => {
          return params.row.active === 1 ? "Yes" : "No";
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => {
          return (
            <ButtonGroup>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.setState({ editUser: { ...params.row } });
                }}
              >
                <Edit />{" "}
              </Button>
            </ButtonGroup>
          );
        },
      },
    ];

    let breadcrumbs = [
      {
        // text: "Home",
        to: "/",
        icon: <Home />,
      },
      {
        text: "Users",
        to: "/users",
        icon: <Group />,
      },
    ];

    if (user_id && user) {
      breadcrumbs = [
        ...breadcrumbs,
        {
          text: user.name,
          to: `/users/${user_id}`,
        },
      ];
    }

    return (
      <div className="users">
        <AutoBreadcrumbs items={breadcrumbs} />
        {user_id && <User user={user} onUpdate={() => this.fetchData(true)} />}
        {!user_id && Array.isArray(users) && (
          <>
            <h1>Users</h1>
            <AdminTable
              rows={users}
              columns={columns}
              autoHeight
              buttons={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.setState({ editUser: {} });
                  }}
                >
                  <Add /> Add
                </Button>
              }
            />
          </>
        )}
        {error && <ErrorMessage title={"Error"} text={error} />}
        {users === null && <CircularProgress />}

        <UserForm
          open={!!editUser}
          user={editUser}
          onSubmit={this.updateUser}
          onClose={() => this.setState({ editUser: null })}
        />
      </div>
    );
  }
}

export default Users;
