import {
  MenuItem,
  Button,
  List,
  TextField,
  CircularProgress,
  Tabs,
  Tab,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React from "react";
import "./style.scss";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

/**
 * Form to select content id (program_id) to link content to base program
 * @param {object} props
 */
class SectionProgramAssignContent extends React.Component {
  state = {
    content_id: null,
    search: "",
    tab_id: 0,
  };

  componentDidMount() {
    // const { category_id, programs } = this.props;
    this.filterPrograms();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.category_id !== this.props.category_id ||
      prevProps.programs !== this.props.programs
    ) {
      this.filterPrograms();
    }
  }

  filterPrograms = () => {
    const { programs = [], category_id } = this.props;
    const search = this.state.search.toLowerCase();

    console.debug(
      "SectionProgramAssignContent filterPrograms for category",
      category_id,
      programs
    );

    const filtered_programs = programs
      // get only programs of given category
      .filter((program) => program?.category_id === category_id)
      .filter(
        (program) =>
          // search within english variant of title and within content slug
          program?.title?.en?.toLowerCase().includes(search) ||
          program?.slug?.toLowerCase()?.includes(search)
      );
    console.debug(
      "SectionProgramAssignContent filtered programs",
      filtered_programs
    );
    this.setState({ filtered_programs });
  };

  onSearch = (e) => {
    const { value } = e.target;
    this.setState({ search: value }, () => this.filterPrograms());
  };

  onSave = async () => {
    const { onConfirm = console.debug, onClose } = this.props;
    if (await onConfirm(this.state.content_id)) {
      onClose();
    }
  };

  onCreate = async (category_id) => {
    const { onCreate = console.debug, onClose } = this.props;
    if (await onCreate(category_id)) {
      onClose();
    }
  };

  tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  render() {
    let { category_id } = this.props;

    category_id = parseInt(category_id);

    const { content_id, filtered_programs, search, tab_id } = this.state;

    if (!Array.isArray(filtered_programs)) return <CircularProgress />;

    console.debug(
      "SectionProgramAssignContent filtered programs count",
      filtered_programs?.length
    );
    return (
      <div className="assign-content">
        <Tabs
          value={tab_id}
          onChange={(e, tab_id) => this.setState({ tab_id })}
        >
          <Tab {...this.tabProps(0)} label="Assign existing content" />
          <Tab {...this.tabProps(1)} label="Create new content" />
        </Tabs>

        <TabPanel value={tab_id} index={0}>
          <>
            <h3>Assign existing content to current program</h3>
            <TextField
              name="search"
              label="Search"
              onChange={this.onSearch}
              value={search}
            />
            <List className="content-items-list">
              {filtered_programs.map((program) => (
                <MenuItem
                  key={`content-item-${program.program_id}`}
                  className={`content-item ${
                    program.program_id === content_id ? "selected" : ""
                  }`}
                  onClick={() =>
                    this.setState({ content_id: program.program_id })
                  }
                >
                  <span>{program.program_id}</span>
                  <span>
                    {program?.title?.en || "Unknown program content"}{" "}
                  </span>
                  <span>{program?.slug}</span>
                </MenuItem>
              ))}
            </List>
            <Button variant="contained" color="primary" onClick={this.onSave}>
              Assign
            </Button>
          </>
        </TabPanel>
        <TabPanel value={tab_id} index={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.onCreate(category_id)}
          >
            <Add /> Create new content
          </Button>
        </TabPanel>
      </div>
    );
  }
}

export default SectionProgramAssignContent;
