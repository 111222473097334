import { CircularProgress } from "@material-ui/core";
import React from "react";
import ProgramService from "../../services/programService";
import ProgramContentEditor from "../ProgramEditor/program-content-editor";
import ErrorMessage from "../template/ErrorMessage";
import "./style.scss";

class SectionProgramCategory extends React.Component {
  state = {
    programs: null,
    program: null,
    content_id: null,
    lang: "en",
  };

  async componentDidMount() {
    await this.fetchData(false, () => this.selectProgram());
  }

  componentDidUpdate(prevProps) {
    // if program or category has changed - refetch content item
    if (
      prevProps.category_slug !== this.props.category_slug ||
      prevProps.program !== this.props.program
    ) {
      this.selectProgram();
    }
  }

  /**
   * Selects program (content item) from programs list.
   */
  selectProgram = () => {
    const {
      // TODO: semantically, this is not category slug, but content item slug, should be renamed
      category_slug: slug,
      program: base_program,
    } = this.props;

    const { programs } = this.state;

    if (!programs) return;

    const content_id = base_program?.content?.find(
      (p) => p.category_slug === slug
    )?.content_id;
    const program = programs.find((p) => p.program_id === content_id);
    console.debug("SectionProgramCategory selectProgram program:", program);
    this.setState({ program, content_id });
  };

  async fetchData(forceReload = false, callback) {
    console.debug("refetching data");
    const programs = await ProgramService.getAllPrograms(forceReload);
    this.setState(
      { programs },
      // when programs are fetched, select current program (content item)
      () => typeof callback === "function" && callback()
    );
  }

  onSave = async (html) => {
    const { program, lang } = this.state;

    if (!program) {
      alert("Program not found!");
      return false;
    }

    const updated = await ProgramService.updateTranslation(
      program.program_id,
      lang,
      html
    );
    if (!updated) {
      alert(ProgramService.error);
      return false;
    }

    // reload programs from server
    this.fetchData(true);
    return true;
  };

  render() {
    const {
      section_id,
      program_id,
      category_slug: slug,
      sections,
      program: base_program,
    } = this.props;

    console.debug("SectionProgramCategory", this.props);

    const { lang = "en", programs, content_id, program } = this.state;

    if (programs === null || !program) return <CircularProgress />;
    if (!programs)
      return (
        <ErrorMessage error="Error loading programs from server, please reload page" />
      );

    console.debug("SectionProgramCategory programs", programs);
    console.debug("SectionProgramCategory program", program);
    // const { html = "", title, slug, program_type, category_slug } = this.props;
    const html = program?.html[lang] || "";
    const title = `${base_program.base_program_title} : ${slug}`;

    return (
      <div className="section-program-category">
        <ProgramContentEditor
          {...{
            html,
            title,
            slug,
            program_id: content_id,
            program_type: program.program_type,
            slug,
            lang,
          }}
          onSave={this.onSave}
        />
      </div>
    );
  }
}

export default SectionProgramCategory;
