import baseClientService from "./baseClientService";

const allUsersCacheId = "all-users";

class UsersService extends baseClientService {
  /**
   * Returns all users as an array. Throws error on failure.
   * @param {*} forceReload
   * @returns
   */
  static async getAll(forceReload = false) {
    const fetch = async (cacheId) => {
      try {
        const users = await this.GET("/admin/users/all");
        if (users) {
          this.cache(cacheId, users);
          return users;
        }
        throw new Error(this.error || "Unknown error");
      } catch (e) {
        throw new Error(e.message || e || "Unknown error");
      }
    };

    // if force reload - just do it;
    if (forceReload) return await fetch(allUsersCacheId);

    return await this.getCachedOr(allUsersCacheId, fetch);
  }

  /**
   * Returns an array of all available credentials
   * @param {*} forceReload
   * @returns
   */
  static async getCredentialList(forceReload = false) {
    const credentialsCacheId = "credentials-list";

    const fetch = async (cacheId) => {
      try {
        const credentialsList = await this.GET("/admin/users/credentials-list");
        if (credentialsList) {
          this.cache(cacheId, credentialsList);
          return credentialsList;
        }
        throw new Error(this.error || "Unknown error");
      } catch (e) {
        throw new Error(e.message || e || "Unknown error");
      }
    };

    // if force reload - just do it;
    if (forceReload) return await fetch(credentialsCacheId);

    return await this.getCachedOr(credentialsCacheId, fetch);
  }

  /**
   * Removes given credentials from user
   * @param {*} user_id
   * @param {*} credential
   * @returns
   */
  static async removeCredential(user_id, credential) {
    try {
      await this.POST(
        `/admin/users/remove-credential/${user_id}/${credential}`
      );
      this.clearCache();
      return true;
    } catch (e) {
      throw new Error(e.message || e || "Unknown error");
    }
  }

  /**
   * Adds given credentials to user
   * @param {*} user_id
   * @param {*} credential
   * @returns
   */
  static async addCredential(user_id, credential) {
    try {
      await this.POST(`/admin/users/add-credential/${user_id}/${credential}`);
      this.clearCache();
      return true;
    } catch (e) {
      throw new Error(e.message || e || "Unknown error");
    }
  }

  static async changePassword(user_id, password) {
    try {
      await this.POST(`/admin/users/change-password/${user_id}`, { password });
      // no need to reset cache here, as passwords aren't part of user data
      return true;
    } catch (e) {
      throw new Error(e.message || e || "Unknown error");
    }
  }

  /**
   * Updates an existing user or creates a new one if id is not provided.
   * @param {object} user
   * @returns
   */
  static async createOrUpdate(user) {
    try {
      return await this.POST(`/admin/users/update`, user);
    } catch (e) {
      throw new Error(e.message || e || "Unknown error");
    }
  }

  static async toggleTranslatorLanguage(user_id, language_id) {
    try {
      return await this.POST(
        `/admin/users/toggle-translator-language/${user_id}/${language_id}`
      );
    } catch (e) {
      // console.debug("message:", e);
      throw new Error(e.message || e || "Unknown error");
    }
  }
}

baseClientService.extend(UsersService);

export default UsersService;
