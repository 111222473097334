import React from "react";
import "./style.scss";
import ModalWindow from "../modal-dialog";
import { CircularProgress } from "@material-ui/core";

class ProcessSpinner extends React.Component {
  render() {
    const { SpinnerComponent = CircularProgress, ...other } = this.props;

    return (
      <ModalWindow
        {...other}
        onClose={(ev, reason) => {
          // don't close on escape and backdrop click
          if (["escapeKeyDown", "backdropClick"].includes(reason)) return false;
        }}
        formClassName="process"
      >
        <div className="flex column align-center">
          <SpinnerComponent />
        </div>
      </ModalWindow>
    );
  }
}

export default ProcessSpinner;
