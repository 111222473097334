import tinymce from "tinymce/tinymce";

/**
 * Adds special blocks to the editor.
 */

const defaultBaseSelector = "p,div";

const items = [
  // {
  //   /**
  //    * Blue-colored unordered list
  //    */
  //   text: "Blue list",
  //   selector: "ul",
  //   id: "blue-list",
  //   onAction: (editor, itemData) => {
  //     const { id } = itemData;
  //     const theFormat = `${id}`;

  //     console.debug("theFormat:", theFormat);
  //     console.debug("editor:", editor);

  //     const formatter = editor.formatter;
  //     const alreadyApplied = formatter.matchAll(items.map((i) => `${i.id}`));
  //     console.debug("alreadyApplied:", alreadyApplied);

  //     // remove all already applied formats, if not optional
  //     if (alreadyApplied.length > 0) {
  //       alreadyApplied.forEach((f) => formatter.remove(f));
  //     }

  //     // if the current format was not previously applied, apply it.
  //     if (
  //       !alreadyApplied.includes(theFormat) &&
  //       formatter.canApply(theFormat)
  //     ) {
  //       formatter.apply(theFormat);
  //       return;
  //     } else {
  //       console.debug("cannot apply");
  //     }

  //     // else - remove it
  //     if (alreadyApplied.includes(theFormat)) {
  //       console.debug("already aaplied! removing...");
  //       formatter.remove(theFormat);
  //     }
  //     return;
  //   },
  // },
  {
    /**
     * diagram image (100% width image without limit to its height)
     */
    text: "Diagram",
    selector: "img",
    id: "diagram-image",
    onAction: (editor, itemData) => {
      const { id } = itemData;
      const theFormat = `${id}`;

      const formatter = editor.formatter;
      const alreadyApplied = formatter.matchAll(items.map((i) => `${i.id}`));

      // if the current format was not previously applied, apply it.
      if (
        !alreadyApplied.includes(theFormat) &&
        formatter.canApply(theFormat)
      ) {
        formatter.apply(theFormat);
        return;
      } else {
        console.debug("cannot apply");
      }

      // else - remove it
      if (alreadyApplied.includes(theFormat)) {
        // console.debug("already aaplied! removing...");
        formatter.remove(theFormat);
      }
      return;
    },
  },
];

export const addButtons = items.map((i) => `${i.id}`).join(" ");
export const addFormats = items.reduce((res, i) => {
  return {
    ...res,
    [`${i.id}`]: {
      selector: i.selector ? i.selector : defaultBaseSelector,
      classes: i.id,
    },
  };
}, {});

tinymce.PluginManager.add("play-and-explore-blocks", function (editor, url) {
  const createButton = (itemData) => {
    const { id, icon, text, onAction } = itemData;

    // Add the button
    editor.ui.registry.addButton(`${id}`, {
      icon,
      text,
      tooltip: text,
      onAction: () => onAction(editor, itemData),
    });
  };

  // add all buttons
  items.forEach((i) => {
    createButton(i);
  });

  // // Adds a menu item, which can then be included in any menu via the menu/menubar configuration
  // editor.ui.registry.addMenuItem('text-field', {
  //   text: 'Inline text field',
  //   onAction: function() {
  //   }
  // });

  return {
    getMetadata: function () {
      return {
        name: "Play & Explore Blocks (Bluefox)",
      };
    },
  };
});
