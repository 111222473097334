import React from "react";
import {
  Add,
  Check,
  Delete,
  Group,
  Home,
  Lock,
  NotInterested,
} from "@material-ui/icons";
import UsersService from "../../services/usersService";
import { notify } from "../../redux/reduxFunctions";
import Spinner from "../template/Spinner";
import {
  Button,
  CircularProgress,
  List,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ConfirmDialog from "../template/ConfirmDialog";
import PasswordChangeForm from "./password-change-form";
import ProcessSpinner from "../template/ProcessSpinner";
import TranslationService from "../../services/translationService";
import AsyncButton from "../template/AsyncButton";

class User extends React.Component {
  state = {
    user: null,
    languages: null,
    confirmHeader: null,
    confirmText: null,
    confirmAction: null,
    credentialList: null,
    credentialsMenuAnchor: null,
    showChangePasswordForm: false,
    processSpinnerHeader: null,
  };

  componentDidMount() {
    // this.fetchData();
    const { user } = this.props;
    this.fetchData();
    this.setState({ user });
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.setState({ user: this.props.user });
      this.fetchData();
    }
  }

  fetchData = async () => {
    const { user } = this.props;
    // console.debug("fetchData user", JSON.stringify(user));
    if (!user || !Array.isArray(user.credentials)) return;
    try {
      let credentialList = await UsersService.getCredentialList();
      // store only credentials that are not already assigned to the user
      credentialList = credentialList.filter(
        (c) => !user.credentials.includes(c.credential_id)
      );
      console.debug("fetchData credentialList", JSON.stringify(credentialList));
      this.setState({ credentialList });
    } catch (error) {
      notify(error);
    }

    // user is translator - fetch languages list
    if (user.credentials.includes("translator")) {
      try {
        const languages = await TranslationService.getLanguages();
        this.setState({ languages });
      } catch (e) {
        notify(e.message || e);
      }
    }
  };

  removeCredential = async (credential) => {
    try {
      const { user } = this.state;
      const { onUpdate = console.debug } = this.props;
      await UsersService.removeCredential(user.id, credential);
      // update available credentials list (remove the added credential from the list)
      this.fetchData();

      // fetch data from the server again
      onUpdate();
    } catch (error) {
      notify(error.message || error || "Error removing credential");
    }
  };

  addCredential = async (credential) => {
    try {
      const { user } = this.state;
      const { onUpdate = console.debug } = this.props;
      await UsersService.addCredential(user.id, credential);
      // update available credentials list (remove the added credential from the list)
      this.fetchData();

      // fetch data from the server again
      onUpdate();
    } catch (error) {
      notify(error.message || error || "Error adding credential");
    }
  };

  changePassword = (password) => {
    this.setState(
      // first - show process spinner
      { processSpinnerHeader: "Changing password..." },
      async () => {
        const { user } = this.state;
        try {
          await UsersService.changePassword(user.id, password).then(() => {
            this.setState({
              processSpinnerHeader: null,
              showChangePasswordForm: false,
            });
            notify("Password changed successfully", "success");
          });
        } catch (e) {
          notify(e.message || e || "Error changing password");
          // remove process spinner
          this.setState({ processSpinnerHeader: null });
        }
      }
    );
  };

  /**
   * Toggle translator allowed language
   * @param {*} language_id
   * @returns
   */
  toggleTranslatorLanguage = async (language_id) => {
    try {
      const { user } = this.state;
      const { onUpdate = console.debug } = this.props;
      // toggle language
      await UsersService.toggleTranslatorLanguage(user.id, language_id);
      // reload user
      onUpdate();
      // return true to asyncButton
      return true;
    } catch (e) {
      notify(e.message);
      return false; // return false to async button
    }
  };

  render() {
    const {
      user,
      languages,
      credentialList,
      confirmHeader,
      confirmAction,
      confirmText,
      credentialsMenuAnchor,
      showChangePasswordForm,
      processSpinnerHeader,
    } = this.state;

    if (!user) return <Spinner />;

    const userIsTranslator = user?.credentials?.includes("translator");
    let allowedLanguages = [];

    if (userIsTranslator) {
      allowedLanguages = [...(user?.config?.translator?.languages || [])];
    }

    const DetailRow = ({ title, value }) => (
      <div className="flex row detail-row">
        <span>{title}</span>
        <span>{value}</span>
      </div>
    );

    return (
      <div className="user">
        <div className="gray-frame flex column user-details">
          <h3>User details</h3>
          <DetailRow title="Name" value={user.name} />
          <DetailRow title="Email" value={user.email} />
          <DetailRow
            title="Status"
            value={user.active ? "Active" : "Not active"}
          />
        </div>
        <List className="credentials gray-frame">
          <h3 role="menuitem">
            Credentials
            {credentialList && credentialList.length > 0 && (
              <>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={(e) =>
                    this.setState({
                      // set menu target
                      credentialsMenuAnchor: e.currentTarget,
                    })
                  }
                >
                  <Add />
                  Add credentials
                </Button>
                <Menu
                  className="credentials-menu"
                  open={Boolean(credentialsMenuAnchor)}
                  anchorEl={credentialsMenuAnchor}
                  onClose={() => this.setState({ credentialsMenuAnchor: null })}
                >
                  {credentialList &&
                    credentialList.map((credential) => (
                      <MenuItem
                        key={credential.credential_id}
                        onClick={() =>
                          this.setState({
                            // show confirm dialog
                            confirmHeader: "Add credential",
                            confirmText: `Add credential '${credential.title}' to user ${user.name}?`,
                            confirmAction: () => {
                              this.addCredential(credential.credential_id);
                              this.setState({
                                confirmHeader: null,
                                confirmText: null,
                                confirmAction: null,
                              });
                            },
                            // hide menu
                            credentialsMenuAnchor: null,
                          })
                        }
                      >
                        {credential.title}
                      </MenuItem>
                    ))}
                </Menu>
              </>
            )}
          </h3>
          {!Array.isArray(credentialList) ? (
            <CircularProgress />
          ) : (
            user.credentials
              // the API returns [null] when no credentials are assigned to the user, so until we fix the API, we need to check for this
              .filter((c) => c)
              .map((credential, ind) => (
                <MenuItem key={credential}>
                  {user.credential_titles[ind]}
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() =>
                      this.setState({
                        confirmHeader: "Remove credential",
                        confirmText: `Are you sure you want to remove the credential '${user.credential_titles[ind]}'?`,
                        confirmAction: () => {
                          // remove credential
                          this.removeCredential(credential);
                          // reset confirm dialog
                          this.setState({
                            confirmHeader: null,
                            confirmText: null,
                            confirmAction: null,
                          });
                        },
                      })
                    }
                  >
                    <Delete />
                  </Button>
                </MenuItem>
              ))
          )}
          {(!Array.isArray(user.credentials) ||
            user.credentials.length === 0 ||
            // the API returns [null] when no credentials are assigned to the user, so until we fix the API, we need to check for this
            user.credentials[0] === null) && <span>No credentials</span>}
        </List>

        <div className="gray-frame">
          <h3>Security</h3>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.setState({ showChangePasswordForm: true })}
          >
            <Lock /> Change password
          </Button>
        </div>

        {userIsTranslator && (
          <div className="gray-frame translator-config">
            <h3>Translator options</h3>
            <List className="flex wrap">
              <li>
                <h4>Allowed languages</h4>
              </li>
              {!languages && <Spinner />}
              {Array.isArray(languages) &&
                languages.map((l) => {
                  const isSelected = allowedLanguages.includes(l.iso639_1);

                  return (
                    <MenuItem>
                      <AsyncButton
                        className="grow1"
                        onClick={async () => {
                          await this.toggleTranslatorLanguage(l.iso639_1);
                        }}
                        icon={
                          isSelected ? (
                            <Check />
                          ) : (
                            <NotInterested htmlColor="#bbb" />
                          )
                        }
                        variant="contained"
                        color={isSelected ? "primary" : "default"}
                      >
                        {l.local_name || "---"}
                      </AsyncButton>
                    </MenuItem>
                  );
                })}
            </List>
          </div>
        )}

        <ConfirmDialog
          open={!!confirmAction}
          header={confirmHeader}
          prompt={confirmText}
          onConfirm={confirmAction}
          onClose={() =>
            this.setState({
              confirmHeader: null,
              confirmText: null,
              confirmAction: null,
            })
          }
        />

        <PasswordChangeForm
          open={showChangePasswordForm}
          onClose={() => this.setState({ showChangePasswordForm: false })}
          onSubmit={this.changePassword}
        />

        <ProcessSpinner
          header={processSpinnerHeader}
          open={!!processSpinnerHeader}
        />
      </div>
    );
  }
}

export default User;
