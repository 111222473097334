import React from "react";
import { Route } from "react-router-dom";
import Page404 from "../Page404";
import Dashboard from "../Dashboard";
import Program from "../Program";
import Programs from "../Programs";
import Sections from "../sections";
import Translations from "../translations";
import Login from "../auth/login";
import store from "../../redux/store";
import { arraysIntersect, getNestedObject } from "../../utils/functions";
import Users from "../users";
import UserProfile from "../users/user-profile";

const Error = (props) => (
  <p>Error reading component for route "{props.match.path}"</p>
);

const components = {
  sections: Sections,
  programs: Programs,
  program: Program,
  dashboard: Dashboard,
  translations: Translations,
  users: Users,
  profile: UserProfile,
  404: Page404,
};

/**
 * Checks route against user config
 * @param {object} user - user object from redux state. should contain .config object
 * @param {object} conditions config_check.conditions element from routes.json.
 *    Contains config_path - to find value in user.config, route_index - to fetch value from route, condition - to compare the two values
 * @param {object} routeValues route.computedMatch.params object - to fetch the route value from it
 * @returns
 */
const checkUserConfig = (user, conditions, routeValues) => {
  console.debug("checking user config...", conditions);

  // value in config
  const config_value = getNestedObject(
    user.config ||
      {
        // translator: {
        //   languages: ["es", "he"],
        // },
      },
    conditions.config_path.split("/")
  );

  // value in route
  const route_value = routeValues[conditions.route_index];

  console.debug("config_value", config_value);
  console.debug("route value", route_value);

  // compare values according to conditions.condition operation
  switch (conditions.condition) {
    case "equal":
      return route_value === config_value;
      break;
    case "includes":
      // config value is treated as an array, a check is done whether it contains route value
      return (
        !route_value ||
        (Array.isArray(config_value) && config_value.includes(route_value))
      );
      break;
    default:
      return true;
  }
};

/**
 * Wrapper for browser route
 */
export default (props) => {
  const { component, credentials = [], config_check, ...other } = props;

  const { params } = other.computedMatch || {};
  console.debug("params", params);

  const user = store.getState().auth.user || {};
  const userIsAdmin = user?.credentials?.includes("admin");
  const loggedOut = !user?.id;
  if (
    // no credentials in route - allow access to all
    !Array.isArray(credentials) ||
    credentials.length === 0 ||
    // user has at least one of the credentials in route - allow access
    (arraysIntersect(credentials, user.credentials) &&
      // CONFIG CHECK is introduced to fine-tune routing based on user configuration,
      // e.g. to block translators from translating languages they don't have permission to translate into.

      // CONFIG CHECK:
      // either there's no config check or config check is passed
      (!config_check ||
        userIsAdmin ||
        checkUserConfig(user, config_check.conditions, params)))
  ) {
    return loggedOut ? <Route component={Login} {...other} /> : <Route component={components[component] || Error} {...other} />;
  }

  return <Page404 />;
};
